import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Vp/viewpro.css";
import axios from "axios";
import { API_BASE_URL } from "../api_config";


function ViewProfile() {
  const [getProf, setGetProf] = useState({});
  const location = useLocation();
  const profId = location.state.userId;

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/get-prof/${profId}`, { withCredentials: true })
      .then((res) => setGetProf({ ...res.data[0] }))
      .catch((err) => alert("Something error occured"));
  }, [profId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const toggleImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isModalOpen) {
        const modalContent = document.querySelector(".preview-modal-content");
        if (!modalContent.contains(event.target)) {
          setIsModalOpen(false);
        }
      }
    };

    window.addEventListener("click", handleOutsideClick, true);

    return () => {
      window.removeEventListener("click", handleOutsideClick, true);
    };
  }, [isModalOpen]);

  function formatDOB(dob) {
    const dobDate = new Date(dob);
    const year = dobDate.getFullYear();
    const month = String(dobDate.getMonth() + 1).padStart(2, "0");
    const day = String(dobDate.getDate()).padStart(2, "0");
    const formattedDOB = `${year}-${month}-${day}`;
    return formattedDOB;
  }

  function numFor(number) {
    const numFormat = Intl.NumberFormat("en-IN");
    const newFor = numFormat.format(number);
    return newFor;
  }

  return (
    <section>
      <div className="view-prof-container">
        <div className="view-prof-left">
          <div className="view-prof-left-top">
            <img
              src={`${API_BASE_URL}/uploads/` + getProf.filename}
              alt={`${getProf.first_name}`}
              className="get-prof-img"
              onClick={() =>
                toggleImageModal(`${API_BASE_URL}/uploads/` + getProf.filename)
              }
            />
            <h1 style={{ textAlign: "center" }}>
              {getProf.first_name} {getProf.last_name}
            </h1>
          </div>
          <div className="view-prof-left-basic-info">
            <p>
              dob: <span>{formatDOB(getProf.dob)}</span>
            </p>
            <p>
              age: <span>{getProf.age}</span>
            </p>
            <p>
              email:
                <span>{getProf.email}</span>
            </p>
            <p>
              mobile no:
                <span>{getProf.mobile_number}</span>
            </p>
            <p className="edu-fit">
              education: <span>{getProf.education}</span>
            </p>
            <p>
              occupation: <span>{getProf.occupation}</span>
            </p>
            <p>
              Company:
                <span>{getProf.company_name}</span>
            </p>
            <p>address: <span>{getProf.address}, {getProf.address1}</span></p>
            <p>
              <span style={{ marginLeft: "0px" }}>
                {getProf.city_name}, {getProf.state_name},{" "}
                {getProf.country_name}
              </span>
            </p>
            <p>
              marital status: <span>{getProf.marital_status}</span>
            </p>
            <p>
              mother tongue: <span>{getProf.mother_tongue}</span>
            </p>
            <p>
              employment type: <span>{getProf.employment_type}</span>
            </p>
            <p>
              income: <span>{numFor(getProf.income)} LPA</span>
            </p>
            <p>
              height: <span>{getProf.height} cm</span>
            </p>
            <p>
              weight: <span>{getProf.weight} kg</span>
            </p>
          </div>
        </div>
        <div className="view-prof-right">
          <div className="view-prof-right-basic-info">
            <p>
              father name: <span>{getProf.father_name}</span>
            </p>
            <p>
              mother name: <span>{getProf.mother_name}</span>
            </p>
            <p>
              family status: <span>{getProf.family_status}</span>
            </p>
            <p>
              family type: <span>{getProf.family_type}</span>
            </p>
            <p>
              religion: <span>{getProf.religion}</span>
            </p>
            <p>
              caste: <span>{getProf.caste}</span>
            </p>
            <p>
              kulam: <span>{getProf.kulam}</span>
            </p>
            <p>
              star: <span>{getProf.star}</span>
            </p>
            <p>
              raasi: <span>{getProf.raasi}</span>
            </p>
            <p>
              chevvai dosham:{" "}
              <span>{getProf.chevvai_dosham === "chevvai" ? "Yes" : "No"}</span>
            </p>
            <p>
              naga dosham:{" "}
              <span>{getProf.naga_dosham === "naga" ? "Yes" : "No"}</span>
            </p>
            <p>
              home details: <span>{getProf.home_details}</span>
            </p>
            <p>
              agri land: <span>{getProf.agri_land}</span>
            </p>
            <p>
              food habits: <span>{getProf.food_habits}</span>
            </p>
          </div>
        </div>
      </div>
        <div className="horoscope-main-container">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <div className="horo-img">
              <div className="horoscope-container">
                <div className="horoscope-box">
                  <p>{getProf.box1 || ""}</p>
                </div>
                <div className="horoscope-box">
                                    <p>{getProf.box2 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box3 || ""}</p>
                </div>
                <div className="horoscope-box">
                                    <p>{getProf.box4 || ""}</p>
                  {/* <input type="text" name="4" value={getProf.box4 || ""} disabled /> */}
                </div>
                <div className="horoscope-box">
                <p>{getProf.box5 || ""}</p>
                </div>
                <div className="horoscope-box"></div>

                <div className="horoscope-box"></div>
                <div className="horoscope-box">
                <p>{getProf.box6 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box7 || ""}</p>
                </div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box">
                <p>{getProf.box8 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box9 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box10 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box11 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box12 || ""}</p>
                </div>
              </div>
            </div>
            <div className="horo-img">
              <div className="horoscope-container">
                <div className="horoscope-box">
                                    <p>{getProf.box13 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box14 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box15 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box16 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box17 || ""}</p>
                </div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box">
                <p>{getProf.box18 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box19 || ""}</p>
                </div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box"></div>
                <div className="horoscope-box">
                <p>{getProf.box20 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box21 || ""}</p>
                </div>
                <div className="horoscope-box">
                                    <p>{getProf.box22 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box23 || ""}</p>
                </div>
                <div className="horoscope-box">
                <p>{getProf.box24 || ""}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      {isModalOpen && (
        <div className="preview-image-modal">
          <div className="preview-modal-content">
            <span className="preview-close" onClick={() => setIsModalOpen(false)}>
              &times;
            </span>
            <img
              src={selectedImage}
              alt="Full Size"
              className="preview-image"
            />
          </div>
        </div>
      )}
    </section>
  );
}

export default ViewProfile;
