import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import UserContext from "../Authcontext";
import { API_BASE_URL } from "../api_config";
import { Link } from "react-router-dom";

export default function Inbox() {
  const { user, token } = useContext(UserContext);
  const [otherlikedUsers, setOtherLikedUsers] = useState([]);

  useEffect(() => {
    if (user && user.other_liked) {
      const otherInterestsArray = user.other_liked.split(",").map(Number);
      axios
        .post(
          `${API_BASE_URL}/api/get-user-by-other-liked`,
          { otherInterestsArray },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (Array.isArray(response.data)) {
            setOtherLikedUsers(response.data);
          }
        })
        .catch((error) => {
          // Handle error if needed
        });
    }
  }, [user, token]);

  return (
    <div>
      {otherlikedUsers.length === 0 ? (
        <div className="no-users-message">There are no users found.</div>
      ) : (
        <ul>
          {otherlikedUsers.map((user, i) => (
            <li className="mail" key={i}>
              <Link
                to={"/view-profile"}
                state={{ userId: user.user_id }}
                className="mailbox-req-link"
              >
                <span className="mailbox-req">{user.first_name}</span> &nbsp; is
                Liked your Profile.
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
