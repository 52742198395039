import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";

import { API_BASE_URL } from "../api_config";
import UserContext from "../Authcontext";
import {
  fetchEducationData,
  fetchMultiKulam,
  fetchMultiStar,
  fetchMultiOccuData,
} from "../apis";
import SearchFilter from "./SearchFilter";

import searchImg from "../../assets/search.png";
import searchNo from "../../assets/srchno.png";

import "./search.css";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "auto",
    minHeight: "40px",
    width: "200px",
    borderRadius: "10px",
    boxShadow: "none",
    borderColor: "#e4e4e4",
    color: "#000000",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "9999",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
    overflowY: "scroll",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e4e4e4",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#333",
    ":hover": {
      backgroundColor: "#e4e4e4",
      color: "#333",
    },
  }),
};

function SearchBox() {
  const { user } = useContext(UserContext);
  const [filterData, setFilterData] = useState([]);
  const [minAge, setMinAge] = useState(20);
  const [maxAge, setMaxAge] = useState(20);
  const [martialstatus, setMaritalStatus] = useState("");
  const [physicalstatus, setPhysicalStatus] = useState("");
  const [mothertongue, setMotherTongue] = useState("");
  const [religion, setReligion] = useState("");
  const [kulam, setKulam] = useState([]);
  const [dosham, setDosham] = useState("");
  const [star, setStar] = useState([]);
  const [education, setEducation] = useState([]);
  // const [employedin, setEmployedIn] = useState("");
  const [occupation, setOccupation] = useState([]);
  const [annualincome, setAnnualIncome] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [smokinghabit, setSmokingHabit] = useState("");
  const [drinkinghabit, setDrinkingHabit] = useState("");
  const [eduData, setEduData] = useState([]);
  const [occData, setOccData] = useState([]);
  const [starData, setStarData] = useState([]);
  const [kulamData, setKulamData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const [showContent, setShowContent] = useState(true);

  const fetchData = (url, successCallback) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
        successCallback(sortedData);
      })
      .catch((error) => alert("Something error occurred, please try again"));
  };

  useEffect(() => {
    fetchData(`${API_BASE_URL}/api/countries`, setCountryData);
    fetchSearchOptions();
  }, []);

  const fetchSearchOptions = async () => {
    const eData = await fetchEducationData();
    const ocuData = await fetchMultiOccuData();
    const strData = await fetchMultiStar();
    const klmData = await fetchMultiKulam();

    setEduData(eData);
    setOccData(ocuData);
    setStarData(strData);
    setKulamData(klmData);
  };

  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value;
    setSelectedCountry(selectedCountryId);
    fetchData(`${API_BASE_URL}/api/states/${selectedCountryId}`, setStateData);
  };

  const handleStateChange = (event) => {
    const selectedStateId = event.target.value;
    setSelectedState(selectedStateId);
    fetchData(`${API_BASE_URL}/api/cities/${selectedStateId}`, setCityData);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const selectedEducation = education.map((edu) => edu.value);
    const selectedKulam = kulam.map((klm) => klm.value);
    const selectedStar = star.map((str) => str.value);
    const selectedOccu = occupation.map((occu) => occu.value);

    axios
      .get(`${API_BASE_URL}/api/Search`, {
        params: {
          id: user.user_id,
          gender: user.gender,
          membership: user.pay_membership,
          minAge,
          maxAge,
          martialstatus,
          physicalstatus,
          mothertongue,
          religion,
          kulam: selectedKulam,
          dosham,
          star: selectedStar,
          education: selectedEducation,
          // employedin,
          occupation: selectedOccu,
          annualincome,
          country,
          state,
          city,
          smokinghabit,
          drinkinghabit,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setShowContent(false);
          setFilterData(res.data);
          if (window.innerWidth >= 700) {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }
      })
      .catch((err) => alert("Something error occured"));
  };

  return (
    <div className="search-main-cont">
      <div className="man">
        <form className="search-box w-100">
          {/* <div className="search-criteria"> */}
          <div className="search-group">
            <label htmlFor="minAge">Min Age</label>
            <div className="search-dropdown">
              <select
                id="minAge"
                value={minAge}
                onChange={(e) => {
                  const selectedValue = parseInt(e.target.value);
                  setMinAge(selectedValue);
                  setMaxAge(selectedValue); // Update maxAge to match minAge
                }}
              >
                {[...Array(61)].map((_, index) => (
                  <option key={index + 20} value={index + 20}>
                    {index + 20}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="maxAge">Max Age</label>
            <div className="search-dropdown">
              <select
                id="maxAge"
                value={maxAge}
                onChange={(e) => setMaxAge(e.target.value)}
              >
                {[...Array(81 - parseInt(minAge, 10))].map((_, index) => {
                  const maxValue = parseInt(minAge, 10) + index;
                  return (
                    <option key={maxValue} value={maxValue}>
                      {maxValue}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="maritalstatus">Marital Status</label>
            <div className="search-dropdown">
              <select
                id="maritalstatus"
                value={martialstatus}
                onChange={(e) => setMaritalStatus(e.target.value)}
                required
              >
                <option value="" disabled="disabled">
                  Marital Status
                </option>
                <option value="Married">Married</option>
                <option value="UnMarried">UnMarried</option>
                <option value="Divorced">Divorced</option>
                <option value="Widow">Widow</option>
                <option value="Awaiting divorce">Awaiting divorce</option>
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="physicalstatus">Physical Status</label>
            <div className="search-dropdown">
              <select
                id="physicalstatus"
                value={physicalstatus}
                onChange={(e) => setPhysicalStatus(e.target.value)}
                required
              >
                <option value="" disabled="disabled">
                  Physical Status
                </option>
                <option value="Any">Any</option>
                <option value="Normal">Normal</option>
                <option value="Physically Challenged">
                  Physically Challenged
                </option>
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="mothertongue">Mother Tongue</label>
            <div className="search-dropdown">
              <select
                id="mothertongue"
                value={mothertongue}
                onChange={(e) => setMotherTongue(e.target.value)}
                required
              >
                <option readOnly>Mother Tongue</option>
                <option value="Tamil">Tamil</option>
                <option value="Telugu">Telugu</option>
                <option value="Malayalam">Malayalam</option>
                <option value="Kannada">Kannada</option>
                <option value="Hindi">Hindi</option>
                <option value="Marathi">Marathi</option>
                <option value="Gujarati">Gujarati</option>
                <option value="Bengali">Bengali</option>
                <option value="Punjabi">Punjabi</option>
                <option value="Urdu">Urdu</option>
                <option value="Sanskrit">Sanskrit</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="search-criteria"> */}
          <div className="search-group">
            <label htmlFor="religion">Religion</label>
            <div className="search-dropdown">
              <select
                id="religion"
                value={religion}
                onChange={(e) => setReligion(e.target.value)}
                required
              >
                <option value="" disabled="disabled">
                  Select Religion
                </option>
                <option value="African Traditional &amp; Diasporic">
                  African Traditional &amp; Diasporic
                </option>
                <option value="Agnostic">Agnostic</option>
                <option value="Atheist">Atheist</option>
                <option value="Baha'i">Baha'i</option>
                <option value="Buddhism">Buddhism</option>
                <option value="Cao Dai">Cao Dai</option>
                <option value="Chinese traditional religion">
                  Chinese traditional religion
                </option>
                <option value="Christianity">Christianity</option>
                <option value="Hinduism">Hinduism</option>
                <option value="Islam">Islam</option>
                <option value="Jainism">Jainism</option>
                <option value="Juche">Juche</option>
                <option value="Judaism">Judaism</option>
                <option value="Neo-Paganism">Neo-Paganism</option>
                <option value="Nonreligious">Nonreligious</option>
                <option value="Rastafarianism">Rastafarianism</option>
                <option value="Secular">Secular</option>
                <option value="Shinto">Shinto</option>
                <option value="Sikhism">Sikhism</option>
                <option value="Spiritism">Spiritism</option>
                <option value="Tenrikyo">Tenrikyo</option>
                <option value="Unitarian-Universalism">
                  Unitarian-Universalism
                </option>
                <option value="Zoroastrianism">Zoroastrianism</option>
                <option value="primal-indigenous">primal-indigenous</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="kulam">Kulam</label>
            <div className="search-dropdown">
              <Select
                id="kulam"
                options={kulamData}
                value={kulam}
                onChange={(selectedOptions) => setKulam(selectedOptions)}
                isMulti
                placeholder="Select Kulam"
                styles={customStyles}
              />
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="dosham">Dosham</label>
            <div className="search-dropdown">
              <select
                id="dosham"
                value={dosham}
                onChange={(e) => setDosham(e.target.value)}
                required
              >
                <option value="">Select dosham</option>
                <option value="Any">Any</option>
                <option value="chevvai">Chevvai dosham</option>
                <option value="naga">Naga Dosham</option>
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="star">Star</label>
            <div className="search-dropdown">
              <Select
                id="star"
                options={starData}
                value={star}
                onChange={(selectedOptions) => setStar(selectedOptions)}
                isMulti
                placeholder="Select Star"
                styles={customStyles}
              />
            </div>
          </div>
          {/* </div> */}
          {/* <div className="search-criteria"> */}
          <div className="search-group">
            <label htmlFor="education">Education</label>
            <div className="search-dropdown">
              <Select
                id="education"
                options={eduData}
                value={education}
                onChange={(selectedOptions) => setEducation(selectedOptions)}
                isMulti
                placeholder="Select Education"
                styles={customStyles}
              />
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="occupation">Occupation</label>
            <div className="search-dropdown">
              <Select
                id="occupation"
                options={occData}
                value={occupation}
                onChange={(selectedOptions) => setOccupation(selectedOptions)}
                isMulti
                placeholder="Select Occupation"
                styles={customStyles}
              />
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="annualincome">Annual Income</label>
            <div className="search-dropdown">
              <select
                id="annualincome"
                value={annualincome}
                onChange={(e) => setAnnualIncome(e.target.value)}
                required
              >
                <option value="">Select Annual Income</option>
                <option value="500000">Less than ₹5,00,000</option>
                <option value="1000000">₹5,00,000 - ₹10,00,000</option>
                <option value="1500000">₹10,00,000 - ₹15,00,000</option>
                <option value="2000000">₹15,00,000 - ₹20,00,000</option>
              </select>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="search-criteria"> */}
          <div className="search-group">
            <label htmlFor="country">Country</label>
            <div className="search-dropdown">
              <select
                id="country"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  handleCountryChange(e);
                }}
                required
              >
                <option readOnly>Country</option>
                {countryData.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="state">State</label>
            <div className="search-dropdown">
              <select
                id="state"
                disabled={!selectedCountry}
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  handleStateChange(e);
                }}
                required
              >
                <option value="">Select State</option>
                {stateData.map((st) => (
                  <option key={st.id} value={st.id}>
                    {st.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="city">City</label>
            <div className="search-dropdown">
              <select
                id="city"
                value={city}
                disabled={!selectedState}
                onChange={(e) => setCity(e.target.value)}
                required
              >
                <option value="">Select City</option>
                {cityData.map((cty) => (
                  <option key={cty.id} value={cty.id}>
                    {cty.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="smoking">Smoking Habit</label>
            <div className="search-dropdown">
              <select
                id="smoking"
                value={smokinghabit}
                onChange={(e) => setSmokingHabit(e.target.value)}
                required
              >
                <option value="">Select smoking habit</option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
                <option value="Occasionally">Occasionally</option>
              </select>
            </div>
          </div>
          <div className="search-group">
            <label htmlFor="drinking">Drinking Habit</label>
            <div className="search-dropdown">
              <select
                id="drinking"
                value={drinkinghabit}
                onChange={(e) => setDrinkingHabit(e.target.value)}
                required
              >
                <option value="">Select drinking habit</option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
                <option value="Occasionally">Occasionally</option>
              </select>
            </div>
          </div>
          {/* </div> */}
        </form>
        <div className="search-btn-container mt-3 pb-4">
          <button
            type="submit"
            className="search-btn btn btn-primary"
            onClick={(e) => handleSearch(e)}
          >
            Search
          </button>
        </div>
      </div>
      {showContent ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <img src={searchImg} alt="SEARCH" style={{ width: "200px" }} />
          <h1>Find your perfect match</h1>
        </div>
      ) : filterData.length === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <img
            src={searchNo}
            alt="NOTHING"
            style={{ width: "200px", marginRight: "50px" }}
          />
          <h1>No matches found, try other filter options</h1>
        </div>
      ) : (
        <SearchFilter filterData={filterData} />
      )}
    </div>
  );
}

export default SearchBox;
