import React from "react";
import regImg1 from "../../assets/reg1.jpg";

const RegContent = () => {
  return (
    <div className="reg-content-cont w-100">
      <img src={regImg1} alt="Tamil Matrimony" className="reg-body-img" />
    </div>
  );
};

export default RegContent;
