import React, { useContext, useState } from "react";
import AuthContext from "../AdminAuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faCogs,
  faUser,
  faSignOutAlt,
  faXmark,
  faCheck,
  faK,
  faM,
  faF,
} from "@fortawesome/free-solid-svg-icons";

import logo from "../Assets/logo.png";

import "./side.css";
import DashboardPage from "../Dashboard/Dash";
import VerifiedProfilesPage from "../Verify/Verify";
import UnverifiedProfilesPage from "../Unverify/Unverify";
import MaleProfiles from "../maleProfiles/MaleProfiles";
import FemaleProfiles from "../femaleProfiles/FemaleProfiles";

import Logout from "../Logout/Logout";
import Welcome from "../Home/Home";
import AddKulam from "../AddKulam/AddKulam";

const Side = () => {
  const { logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("Home");

  const handleTrigger = () => setIsOpen(!isOpen);

  const handleSidebarClick = (page) => {
    if (page === "Logout") {
      handleLogout();
    } else {
      setCurrentPage(page);
      setIsOpen(false);
    }
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={`color ${isOpen ? "open" : ""}`}>
      <div className="header">
        <div className="header__logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>

      <div className="page">
        <div className={`sidebar ${isOpen ? "sidebar--open" : ""}`}>
          <div className="trigger" onClick={handleTrigger}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
          </div>

          <div
            className={`sidebar-position ${
              currentPage === "Home" ? "active" : ""
            }`}
            onClick={() => handleSidebarClick("Home")}
          >
            <FontAwesomeIcon icon={faUser} />
            <span>Home</span>
          </div>
          <div
            className={`sidebar-position ${
              currentPage === "Dashboard" ? "active" : ""
            }`}
            onClick={() => handleSidebarClick("Dashboard")}
          >
            <FontAwesomeIcon icon={faCogs} />
            <span>Dashboard</span>
          </div>
          <div
            className={`sidebar-position ${
              currentPage === "Verified Profiles" ? "active" : ""
            }`}
            onClick={() => handleSidebarClick("Verified Profiles")}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span>Verified Profiles</span>
          </div>
          <div
            className={`sidebar-position ${
              currentPage === "Unverified Profiles" ? "active" : ""
            }`}
            onClick={() => handleSidebarClick("Unverified Profiles")}
          >
            <FontAwesomeIcon icon={faXmark} />
            <span>Unverified Profiles</span>
          </div>
          <div
            className={`sidebar-position ${
              currentPage === "Male Profiles" ? "active" : ""
            }`}
            onClick={() => handleSidebarClick("Male Profiles")}
          >
            <FontAwesomeIcon icon={faM} />
            <span>Male Profiles</span>
          </div>
          <div
            className={`sidebar-position ${
              currentPage === "Female Profiles" ? "active" : ""
            }`}
            onClick={() => handleSidebarClick("Female Profiles")}
          >
            <FontAwesomeIcon icon={faF} />
            <span>Female Profiles</span>
          </div>
          <div
            className={`sidebar-position ${
              currentPage === "Kulam" ? "active" : ""
            }`}
            onClick={() => handleSidebarClick("Kulam")}
          >
            <FontAwesomeIcon icon={faK} />
            <span>Kulam</span>
          </div>
          <div
            className={`sidebar-position ${
              currentPage === "Logout" ? "active" : ""
            }`}
            onClick={() => handleSidebarClick("Logout")}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span>Logout</span>
          </div>
        </div>

        <div className={`content ${isOpen ? "content--open" : ""}`}>
          {currentPage === "Home" && <Welcome />}
          {currentPage === "Dashboard" && <DashboardPage />}
          {currentPage === "Verified Profiles" && <VerifiedProfilesPage />}
          {currentPage === "Unverified Profiles" && <UnverifiedProfilesPage />}
          {currentPage === "Male Profiles" && <MaleProfiles />}
          {currentPage === "Female Profiles" && <FemaleProfiles />}
          {currentPage === "Kulam" && <AddKulam />}
          {currentPage === "Logout" && <Logout />}
        </div>
      </div>
    </div>
  );
};

export default Side;
