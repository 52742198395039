import { useContext, useEffect, useState } from "react";
import UserContext from "../Authcontext";
import {
  fetchCasteOptions,
  fetchEducationData,
  fetchMultiKulam,
  fetchMultiRaasi,
  fetchMultiStar,
  fetchOccupations,
} from "../apis";
import Select from "react-select";
import "./EditPro.css";
import axios from "axios";
import { API_BASE_URL } from "../api_config";
import { useNavigate } from "react-router-dom";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "auto",
    minHeight: "40px",
    borderRadius: "10px",
    boxShadow: "none",
    borderColor: "#e4e4e4",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "9999",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
    overflowY: "scroll",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e4e4e4",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#333",
    ":hover": {
      backgroundColor: "#e4e4e4",
      color: "#333",
    },
  }),
};

function EditPreference() {
  const { user, token, fetchUserData } = useContext(UserContext);

  const [pMinAge, setPMinAge] = useState(user.pmin_age || 20);
  const [pMaxAge, setPMaxAge] = useState(user.pmax_age || 20);
  const [pmarital, setPMarital] = useState(user.pmarital_status || "");
  const [mtongue, setMtongue] = useState(user.pmother_tongue || "");
  const [peducation, setPeducation] = useState([]);
  const [employ, setemploy] = useState(user.pemployment_type || "");
  const [occupa, setOccupa] = useState(user.poccupation || "");
  const [anlincome, setAnlincome] = useState(user.pannual_income || "");
  const [preligion, setPreligion] = useState(user.preligion || "");
  const [pcaste, setPcaste] = useState(user.pcaste || "");
  const [pkulam, setPkulam] = useState([]);
  const [pstar, setPStar] = useState([]);
  const [praasi, setPRaasi] = useState([]);
  const [pheight, setPheight] = useState(user.pheight || "");
  const [pweight, setPweight] = useState(user.pweight || "");
  const [pbodytype, setPbodytype] = useState(user.pbody_type || "");
  const [pcomplexion, setPcomplexion] = useState(user.pcomplexion || "");
  const [pphysical, setPphysical] = useState(user.pphysical_status || "");

  const [edtEducationData, setEdtEducationData] = useState([]);
  const [edtOccupationData, setEdtOccupationData] = useState([]);
  const [edtRaasiOptions, setEdtRaasiOptions] = useState([]);
  const [edtStarOptions, setEdtStarOptions] = useState([]);
  const [edtKulamOptions, setEdtKulamOptions] = useState([]);
  const [edtCasteOptions, setEdtCasteOptions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    setPMinAge(user.pmin_age || 20);
    setPMaxAge(user.pmax_age || 20);
    setPMarital(user.pmarital_status || "Any");
    setMtongue(user.pmother_tongue || "Any");
    setemploy(user.pemployment_type || "Any");
    setOccupa(user.poccupation || "Any");
    setAnlincome(user.pannual_income || "Any");
    setPreligion(user.preligion || "Any");
    setPcaste(user.pcaste || "Any");
    setPheight(user.pheight || "Any");
    setPweight(user.pweight || "Any");
    setPbodytype(user.pbody_type || "Any");
    setPcomplexion(user.pcomplexion || "Any");
    setPphysical(user.pphysical_status || "Any");

    if (user.phighest_education) {
      const selectedOptions = user.phighest_education
        .split(",")
        .map((value) => {
          return { value: value.trim(), label: value.trim() };
        });

      if (selectedOptions.some((option) => option.value === "Any")) {
        setPeducation([{ value: "Any", label: "Any" }]);
      } else {
        const validPeducation = selectedOptions.filter((option) =>
          edtEducationData.some((eduOption) => eduOption.value === option.value)
        );
        setPeducation(
          validPeducation.length > 0
            ? validPeducation
            : [{ value: "Any", label: "Any" }]
        );
      }
    } else {
      setPeducation([{ value: "Any", label: "Any" }]);
    }

    if (user.pkulam) {
      const selectedOptions = user.pkulam.split(",").map((value) => {
        return { value: value.trim(), label: value.trim() };
      });

      if (selectedOptions.some((option) => option.value === "Any")) {
        setPkulam([{ value: "Any", label: "Any" }]);
      } else {
        const validPKulam = selectedOptions.filter((option) =>
          edtKulamOptions.some(
            (kulamOption) => kulamOption.value === option.value
          )
        );
        setPkulam(
          validPKulam.length > 0
            ? validPKulam
            : [{ value: "Any", label: "Any" }]
        );
      }
    } else {
      setPkulam([{ value: "Any", label: "Any" }]);
    }

    if (user.pstar) {
      const selectedOptions = user.pstar.split(",").map((value) => {
        return { value: value.trim(), label: value.trim() };
      });

      if (selectedOptions.some((option) => option.value === "Any")) {
        setPStar([{ value: "Any", label: "Any" }]);
      } else {
        const validPStar = selectedOptions.filter((option) =>
          edtStarOptions.some((starOption) => starOption.value === option.value)
        );
        setPStar(
          validPStar.length > 0 ? validPStar : [{ value: "Any", label: "Any" }]
        );
      }
    } else {
      setPStar([{ value: "Any", label: "Any" }]);
    }

    if (user.praasi) {
      const selectedOptions = user.praasi.split(",").map((value) => {
        return { value: value.trim(), label: value.trim() };
      });

      if (selectedOptions.some((option) => option.value === "Any")) {
        setPRaasi([{ value: "Any", label: "Any" }]);
      } else {
        const validPRaasi = selectedOptions.filter((option) =>
          edtRaasiOptions.some(
            (raasiOption) => raasiOption.value === option.value
          )
        );
        setPRaasi(
          validPRaasi.length > 0
            ? validPRaasi
            : [{ value: "Any", label: "Any" }]
        );
      }
    } else {
      setPRaasi([{ value: "Any", label: "Any" }]);
    }
  }, [
    user,
    edtRaasiOptions,
    edtStarOptions,
    edtEducationData,
    edtKulamOptions,
  ]);

  const fetchOptions = async () => {
    const eduData = await fetchEducationData();
    const occData = await fetchOccupations();
    const casteData = await fetchCasteOptions();
    const raasiData = await fetchMultiRaasi();
    const starData = await fetchMultiStar();
    const kulamData = await fetchMultiKulam();

    setEdtEducationData(eduData);
    setEdtOccupationData(occData);
    setEdtRaasiOptions(raasiData);
    setEdtStarOptions(starData);
    setEdtKulamOptions(kulamData);
    setEdtCasteOptions(casteData);
  };

  const onEditPP = async (e) => {
    e.preventDefault();
    const selectedPEducations = peducation.map((edu) => edu.value);
    const selectedPKulam = pkulam.map((klm) => klm.value);
    const selectedPStar = pstar.map((str) => str.value);
    const selectedPRaasi = praasi.map((rasi) => rasi.value);
    try {
      await axios
        .put(
          `${API_BASE_URL}/api/update-partner-details`,
          {
            pMinAge,
            pMaxAge,
            pmarital,
            mtongue,
            peducation: selectedPEducations,
            employ,
            occupa,
            anlincome,
            preligion,
            pcaste,
            pkulam: selectedPKulam,
            pstar: selectedPStar,
            praasi: selectedPRaasi,
            pheight,
            pweight,
            pbodytype,
            pcomplexion,
            pphysical,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          fetchUserData();
          navigate("/");
        });
    } catch (error) {
      alert("Failed to update partner details");
    }
  };

  return (
    <div className="container co1">
      <header>Edit Partner Preference</header>
      <form onSubmit={onEditPP}>
        <div className="form1">
          <div className="details personal">
            {/* <span className="title">Personal Details</span> */}
            <div className="fields">
              <div className="input-field">
                <label>Marital Status</label>
                <select
                  required
                  value={pmarital}
                  onChange={(e) => setPMarital(e.target.value)}
                >
                  <option readOnly>Marital Status</option>
                  <option value="Married">Married</option>
                  <option value="UnMarried">UnMarried</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widow">Widow</option>
                  <option value="Awaiting divorce">Awaiting divorce</option>
                  <option value="Any">Any</option>
                </select>
              </div>
              <div className="input-field">
                <label>Mother Tongue</label>
                <select
                  value={mtongue}
                  id="tongue"
                  className="input--style-2  browser-default"
                  onChange={(e) => setMtongue(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="Tamil">Tamil</option>
                  <option value="Telugu">Telugu</option>
                  <option value="Malayalam">Malayalam</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Marathi">Marathi</option>
                  <option value="Gujarati">Gujarati</option>
                  <option value="Bengali">Bengali</option>
                  <option value="Punjabi">Punjabi</option>
                  <option value="Urdu">Urdu</option>
                  <option value="Sanskrit">Sanskrit</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="input-field">
                <label>Highest Education</label>
                <Select
                  options={edtEducationData}
                  value={peducation}
                  onChange={(selectedOptions) => {
                    // Check if 'Any' is selected
                    const isAnySelected = selectedOptions.some(
                      (option) => option.value === "Any"
                    );

                    // If 'Any' is selected, keep only 'Any' as the selected option
                    if (isAnySelected) {
                      setPeducation([{ label: "Any", value: "Any" }]);
                    } else {
                      setPeducation(selectedOptions);
                    }
                  }}
                  isMulti
                  placeholder="Select Education"
                  styles={customStyles}
                />
              </div>
              <div className="input-field">
                <label>Employment Type</label>
                <select
                  value={employ}
                  id="employ"
                  className="input--style-2  browser-default"
                  onChange={(e) => setemploy(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="Employed">Employed</option>
                  <option value="Self-employed">Self-employed</option>
                  <option value="Out of work">Out of work</option>
                  <option value="Homemaker">Homemaker</option>
                  <option value="Student">Student</option>
                  <option value="Retired">Retired</option>
                  <option value="Unable to work">Unable to work</option>
                </select>
              </div>
              <div className="input-field">
                <label>Occupation</label>
                <select
                  value={occupa}
                  id="occupation"
                  className="input--style-2  browser-default"
                  onChange={(e) => setOccupa(e.target.value)}
                >
                  <option value="Any">Any</option>
                  {edtOccupationData.map((occ) => (
                    <option key={occ.id} value={occ.occupation}>
                      {occ.occupation}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Annual Income</label>
                <select
                  id="annualincome"
                  value={anlincome}
                  name="income"
                  className="input--style-2  browser-default"
                  onChange={(e) => setAnlincome(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="500000">Less than ₹5,00,000</option>
                  <option value="1000000">₹5,00,000 - ₹10,00,000</option>
                  <option value="1500000">₹10,00,000 - ₹15,00,000</option>
                  <option value="2000000">₹15,00,000 - ₹20,00,000</option>
                </select>
              </div>
            </div>
          </div>

          <div className="details ID">
            {/* <span className="title">Religious Information</span> */}
            <div className="fields">
              <div className="input-field">
                <label>Religious</label>
                <select
                  value={preligion}
                  id="religion"
                  className="input--style-2  browser-default"
                  onChange={(e) => setPreligion(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="African Traditional &amp; Diasporic">
                    African Traditional &amp; Diasporic
                  </option>
                  <option value="Agnostic">Agnostic</option>
                  <option value="Atheist">Atheist</option>
                  <option value="Baha'i">Baha'i</option>
                  <option value="Buddhism">Buddhism</option>
                  <option value="Cao Dai">Cao Dai</option>
                  <option value="Chinese traditional religion">
                    Chinese traditional religion
                  </option>
                  <option value="Christianity">Christianity</option>
                  <option value="Hindu">Hinduism</option>
                  <option value="Islam">Islam</option>
                  <option value="Jainism">Jainism</option>
                  <option value="Juche">Juche</option>
                  <option value="Judaism">Judaism</option>
                  <option value="Neo-Paganism">Neo-Paganism</option>
                  <option value="Nonreligious">Nonreligious</option>
                  <option value="Rastafarianism">Rastafarianism</option>
                  <option value="Secular">Secular</option>
                  <option value="Shinto">Shinto</option>
                  <option value="Sikhism">Sikhism</option>
                  <option value="Spiritism">Spiritism</option>
                  <option value="Tenrikyo">Tenrikyo</option>
                  <option value="Unitarian-Universalism">
                    Unitarian-Universalism
                  </option>
                  <option value="Zoroastrianism">Zoroastrianism</option>
                  <option value="primal-indigenous">primal-indigenous</option>
                  <option value="Any">Any</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="input-field">
                <label>Caste</label>
                <select
                  value={pcaste}
                  id="caste"
                  className="input--style-2  browser-default"
                  onChange={(e) => setPcaste(e.target.value)}
                >
                  <option value="Any">Any</option>
                  {edtCasteOptions.map((caste) => (
                    <option key={caste.id} value={caste.caste}>
                      {caste.caste}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Kulam</label>
                <Select
                  options={edtKulamOptions}
                  value={pkulam}
                  onChange={(selectedOptions) => {
                    // Check if 'Any' is selected
                    const isAnySelected = selectedOptions.some(
                      (option) => option.value === "Any"
                    );

                    // If 'Any' is selected, keep only 'Any' as the selected option
                    if (isAnySelected) {
                      setPkulam([{ label: "Any", value: "Any" }]);
                    } else {
                      setPkulam(selectedOptions);
                    }
                  }}
                  isMulti
                  placeholder="Select Kulam"
                  styles={customStyles}
                />
              </div>
              <div className="input-field">
                <label>Raasi</label>
                <Select
                  options={edtRaasiOptions}
                  value={praasi}
                  onChange={(selectedOptions) => {
                    // Check if 'Any' is selected
                    const isAnySelected = selectedOptions.some(
                      (option) => option.value === "Any"
                    );

                    // If 'Any' is selected, keep only 'Any' as the selected option
                    if (isAnySelected) {
                      setPRaasi([{ label: "Any", value: "Any" }]);
                    } else {
                      setPRaasi(selectedOptions);
                    }
                  }}
                  isMulti
                  placeholder="Select Raasi"
                  styles={customStyles}
                />
              </div>
              <div className="input-field">
                <label>Min Age</label>
                <select
                  id="minAge"
                  value={pMinAge}
                  onChange={(e) => {
                    const selectedValue = parseInt(e.target.value);
                    setPMinAge(selectedValue);
                    setPMaxAge(selectedValue); // Update maxAge to match minAge
                  }}
                >
                  {[...Array(61)].map((_, index) => (
                    <option key={index + 20} value={index + 20}>
                      {index + 20}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Max Age</label>
                <select
                  id="maxAge"
                  value={pMaxAge}
                  onChange={(e) => setPMaxAge(e.target.value)}
                >
                  {[...Array(81 - parseInt(pMinAge, 10))].map((_, index) => {
                    const maxValue = parseInt(pMinAge, 10) + index;
                    return (
                      <option key={maxValue} value={maxValue}>
                        {maxValue}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="details ID">
            <div className="fields">
              <div className="input-field">
                <label>Star</label>
                <Select
                  options={edtStarOptions}
                  value={pstar}
                  onChange={(selectedOptions) => {
                    // Check if 'Any' is selected
                    const isAnySelected = selectedOptions.some(
                      (option) => option.value === "Any"
                    );

                    // If 'Any' is selected, keep only 'Any' as the selected option
                    if (isAnySelected) {
                      setPStar([{ label: "Any", value: "Any" }]);
                    } else {
                      setPStar(selectedOptions);
                    }
                  }}
                  isMulti
                  placeholder="Select Star"
                  styles={customStyles}
                />
              </div>
              <div className="input-field">
                <label>Height</label>
                <select
                  value={pheight}
                  id="height"
                  className="input--style-2  browser-default"
                  onChange={(e) => setPheight(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="150">120-150 cm</option>
                  <option value="170">150-170 cm</option>
                  <option value="190">170-190 cm</option>
                  <option value="210">190-210 cm</option>
                </select>
              </div>
              <div className="input-field">
                <label>Weight</label>
                <select
                  value={pweight}
                  id="weight"
                  className="input--style-2  browser-default"
                  onChange={(e) => setPweight(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="60">40-60 kg</option>
                  <option value="80">60-80 kg</option>
                  <option value="100">80-100 kg</option>
                  <option value="150">100-150 kg</option>
                </select>
              </div>
              <div className="input-field">
                <label>Body Type</label>
                <select
                  value={pbodytype}
                  id="bodytype"
                  className="input--style-2  browser-default"
                  onChange={(e) => setPbodytype(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="Slim">Slim</option>
                  <option value="Average">Average</option>
                  <option value="Athletic">Athletic</option>
                  <option value="Heavy">Heavy</option>
                </select>
              </div>
              <div className="input-field">
                <label>Complexion</label>
                <select
                  value={pcomplexion}
                  id="complexion"
                  className="input--style-2  browser-default"
                  onChange={(e) => setPcomplexion(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="Very Fair">Very Fair</option>
                  <option value="Fair">Fair</option>
                  <option value="Wheatish">Wheatish</option>
                  <option value="Wheatish Brown">Wheatish Brown</option>
                  <option value="Dark">Dark</option>
                </select>
              </div>
              <div className="input-field">
                <label>Physical Status</label>
                <select
                  value={pphysical}
                  id="physical"
                  className="input--style-2  browser-default "
                  onChange={(e) => setPphysical(e.target.value)}
                >
                  <option value="Any">Any</option>
                  <option value="Normal">Normal</option>
                  <option value="Physically Challenged">
                    Physically Challenged
                  </option>
                </select>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button className="nextBtn" type="submit">
                {/* <Link className="btnText" to="/home"> */}
                Save Profile
                {/* </Link> */}
                <i className="uil uil-navigator"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditPreference;
