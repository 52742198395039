import React, { useEffect, useState } from "react";
import "./addkulam.css";
import { API_BASE_URL } from "../../api_config";
import axios from "axios";

export default function AddKulam() {
  const [kulam, setKulam] = useState("");
  const [kulamData, setKulamData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editKulam, setEditKulam] = useState("");

  useEffect(() => {
    getKulamData();
  }, []);

  const getKulamData = () => {
    axios
      .get(`${API_BASE_URL}/api/kulam`, { withCredentials: true })
      .then((res) => setKulamData(res.data))
      .catch((err) => alert("Something error occured"));
  };

  const handleClickAddKulam = () => {
    if (editIndex !== null) {
      const updatedKulam = {
        id: kulamData[editIndex].id,
        kulam: editKulam,
      };

      axios
        .put(
          `${API_BASE_URL}/api/admin/update-kulam`,
          { updatedKulam },
          { withCredentials: true }
        )
        .then(() => {
          setEditIndex(null);
          setEditKulam("");
          getKulamData();
        })
        .catch((err) => console.log(err));
    } else {
      if (kulam.trim() !== "") {
        axios
          .post(
            `${API_BASE_URL}/api/admin/add-kulam`,
            { addKulam: kulam },
            { withCredentials: true }
          )
          .then(() => {
            setKulam("");
            getKulamData();
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleDeleteKulam = (id) => {
    axios
      .delete(`${API_BASE_URL}/api/admin/delete-kulam/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res);
        getKulamData();
      })
      .catch((err) => console.log(err));
  };

  const handleClickEdit = (index, kulam) => {
    setEditIndex(index);
    setEditKulam(kulam);
  };

  return (
    <div className="dashboard-container">
      <h2 className="dashboard-heading">Add Kulam</h2>
      <div className="add-kulam-cont">
        <input
          type="text"
          value={kulam}
          placeholder="Kulam"
          onChange={(e) => setKulam(e.target.value)}
          className="kulam-inp"
          required
        />
        <button type="button" onClick={handleClickAddKulam}>
          Add Kulam
        </button>
      </div>
      <table className="dashboard-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>KULAM</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {kulamData.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {index === editIndex ? (
                  <div className="edit-kulam-cell">
                    <input
                      type="text"
                      value={editKulam}
                      onChange={(e) => setEditKulam(e.target.value)}
                      className="kulam-inp"
                    />
                    <span
                      className="cross-icon"
                      onClick={() => setEditIndex(null)}
                    >
                      &times;
                    </span>
                  </div>
                ) : (
                  item.kulam
                )}
              </td>

              <td>
                {index === editIndex ? (
                  <button onClick={handleClickAddKulam}>Update Kulam</button>
                ) : (
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem"}}>
                    <button onClick={() => handleClickEdit(index, item.kulam)}>
                      Edit
                    </button>
                    <button onClick={() => handleDeleteKulam(item.id)}>
                      Delete
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
