import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../api_config";
import UserContext from "../../Authcontext";
import Footer from "../../Foote/Footer";

function Success() {
  const { token, fetchUserData } = useContext(UserContext);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const session_id = searchParams.get("session_id");

  useEffect(() => {
    if (session_id) {
      axios
        .put(`${API_BASE_URL}/api/payment/success?session_id=${session_id}`, {},{
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        })
        .then((response) => {
          fetchUserData()
        })
        .catch((error) => {
          // Handle error, you can redirect or render an error message
        });
    }
  }, [session_id, token, fetchUserData]);

  const handleViewAllPackages = () => {
    navigate("/");
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="container text-center py-5 flex-grow-1">
        <div className="jumbotron">
          <h2 className="display-4 mb-4">Thank You!</h2>
          <h4>Your payment was successful.</h4>
          <p className="lead">
            We appreciate your business! If you have any questions, please email us
            at{" "}
            <a href="mailto:tamilvokkaligamatrimony@gmail.com">
              tamilvokkaligamatrimony@gmail.com
            </a>
            .
          </p>
          <button
            className="btn btn-primary mt-3 mb-2"
            onClick={handleViewAllPackages}
          >
            Back to Home
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Success;
