import React, { useState } from "react";
import "./mailbox.css";
import Sentbox from "./Sentbox";
import Inbox from "./Inbox";

const Mailbox = () => {
  const [activeTab, setActiveTab] = useState("accepting");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="mailbox-container">
      <div className="sidenav">
        <button
          className={activeTab === "accepting" ? "active" : ""}
          onClick={() => handleTabClick("accepting")}
        >
          Admired by
        </button>

        <button
          className={activeTab === "sent" ? "active" : ""}
          onClick={() => handleTabClick("sent")}
        >
          My Admires
        </button>
      </div>
      <div className="main">
        <div className="mails">
          {activeTab === "accepting" && <Inbox />}
          {activeTab === "sent" && <Sentbox />}
        </div>
      </div>
    </div>
  );
};

export default Mailbox;