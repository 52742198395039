import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import UserContext from "../Authcontext";
import { API_BASE_URL } from "../api_config";
import { Link } from "react-router-dom";

export default function Sentbox() {
  const { user, token } = useContext(UserContext);
  const [likedUsers, setLikedUsers] = useState([]);

  useEffect(() => {
    if (user && user.my_interest) {
      const interestsArray = user.my_interest.split(",").map(Number);
      axios
        .post(
          `${API_BASE_URL}/api/get-user-by-my-interest`,
          { interestsArray },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (Array.isArray(response.data)) {
            setLikedUsers(response.data);
          }
        })
        .catch((error) => {
          // Handle error if needed
        });
    }
  }, [user, token]);

  return (
    <div>
      {likedUsers.length === 0 ? (
        <div className="no-users-message">There are no users found.</div>
      ) : (
        <ul>
          {likedUsers.map((user, i) => (
            <li className="mail" key={i}>
              <Link
                to={"/view-profile"}
                state={{ userId: user.user_id }}
                className="mailbox-req-link"
              >
                you sent a like to &nbsp;
                <span className="mailbox-req">{user.first_name}</span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
