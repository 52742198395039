import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Load.css';
import leftImage from '../../assets/right-image.png';
import rightImage from '../../assets/left-image.png';
import { FaHeart } from 'react-icons/fa';

const Load = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [leftImageOffset, setLeftImageOffset] = useState(0);
  const [rightImageOffset, setRightImageOffset] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        const newProgress = progress + 10;
        setProgress(newProgress);
        const offset = (newProgress / 100) * 150; // Calculate the offset based on the progress
        setLeftImageOffset(offset);
        setRightImageOffset(offset);
      } else if (progress === 100) {
        setProgress(100);
        setLeftImageOffset(150);
        setRightImageOffset(150);
      }
      
      // if (progress === 70) {
      //   // Redirect to the next page when progress reaches 70
      //   navigate('/next-page');
      // }
    }, 1); // Increase the progress every 1 second

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [progress, navigate]);

  return (
    <div className="loader-container">
      <div className="images-container">
        <img
          className="left-image"
          src={leftImage}
          alt="Left"
          style={{ transform: `translateX(${leftImageOffset}%)` }}
        />
        <img
          className="right-image"
          src={rightImage}
          alt="Right"
          style={{ transform: `translateX(-${rightImageOffset}%)` }}
        />
        {progress === 100 && <FaHeart className="heart-icon" />}
      </div>
      <div className="loader">
        <div className="loader-progress" style={{ width: `${progress}%` }}></div>
        {/* <div className="loader-percentage">{`${progress}%`}</div> */}
      </div>
    </div>
  );
};

export default Load;