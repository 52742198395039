import { Fragment } from "react";
import { Row, Col } from "react-bootstrap";

const Privacy = () => {
  return (
    <Fragment>
      <Row>
        <Col xs={12} lg={2}></Col>
        <Col xs={12} lg={8}>
          <div
            style={{
              border: "1px solid grey",
              margin: "20px",
              padding: "20px",
            }}
          >
            <h6>
              <u style={{ color: "grey" }}>
                <span style={{ color: "black" }}>
                  Privacy Policy of TamilVokkaligaMatrimony
                </span>
              </u>
            </h6>
            <div style={{ padding: "10px" }}>
              <span style={{ fontSize: "12px" }}>
                TamilVokkaligaMatrimony.com is an online matrimonial portal
                dedicated to providing matrimonial services.This privacy
                statement applies to all the matrimonial websites/apps operated
                under TamilVokkaligaMatrimony.com. Committed to respecting your
                right to privacy,we have outlined a privacy statement regarding
                the information we collect from you.You acknowledge that you are
                voluntarily disclosing information. By accessing/using the
                website/apps and/or providing your information.you consent to
                the collection, use, sharing, storing, and processing of the
                information you disclose on the website/apps in accordance with
                this Privacy Policy.
                <br />
                <span style={{ color: "grey", padding: "5px" }}>
                  If you do not agree for use of your information, please do not
                  use or access this website/apps.
                </span>
                <br />
                <br />
                <h6>
                  What information you need to give in to use this Website/apps?
                </h6>
                TamilVokkaligaMatrimony.com gathers information from members and
                visitors applying for various services on our website/apps.
                offers includes,but is not limited to, user photos, profile
                videos, email address, name, date of birth, educational
                qualifications, voluntarily submitted identity proof documents
                for verification, user-specified password, mailing address,
                zip/pin code, and telephone/mobile number or fax number.
                <br />
                <br />
                For secure credit card transactions, we utilize a secure server
                to protect user credit card information. Cookies are employed to
                store login information. Additionally, you may encounter cookies
                placed by third parties on specific pages, though we do not
                control their usage.
                <br />
                <br />
                When establishing a credit account for fee payments, we collect
                additional information, including billing address, credit/debit
                card number, credit/debit card expiration date, and tracking
                information from cheques or demand drafts.
                <br />
                <br />
                User information collected depends on the interaction context,
                choices made, and the products/features used. This information
                is utilized for authentication, account access, and may include
                data from social networking platforms (e.g., Facebook, Google)
                if registered through them. Automatic collection of data, such
                as device ID, log files, geographic location, and device
                specifications, occurs when users access our websites or apps.
                <br />
                <br />
                Personal information may be used for verification, data
                analysis, trend analysis, site/app improvement, marketing
                research, and fraud prevention. We collect and analyze
                demographic and profile data for continuous product and service
                enhancement. IP addresses are used for server problem diagnosis,
                website/app administration, user identification, and to gather
                broad demographic information.
                <br />
                <br />
                <h6>
                  How the website/apps uses the information it collects/tracks?
                </h6>
                TamilVokkaligaMatrimony.com collects user information for data
                analysis, identifying usage trends, assessing the effectiveness
                of promotional campaigns, and evaluating and enhancing our
                websites, apps, products, and services. This is done to meet
                your requirements and provide a personalized experience. The
                data is utilized primarily for fulfilling user needs and
                conducting marketing research to ensure a tailored and effective
                service.
                <br />
                <br />
                <h6>
                  With whom the website/apps shares the information it
                  collects/tracks?
                </h6>
                TamilVokkaligaMatrimony.com may share identifiable information
                with our associates, affiliates, and subsidiaries, who may
                market to you based on this sharing. Your information is handled
                with utmost care and security. We are committed to full
                cooperation in situations where legal requirements necessitate
                providing information about a customer or visitor.
                <br />
                <br />
                In compliance with the law, information may be shared with
                regulators, law enforcement agencies, or to protect the rights,
                property, or personal safety of other members or the general
                public. We may also voluntarily share information with law
                enforcement agencies, gateway service providers, or anti-fraud
                solution providers if a transaction is deemed suspicious.
                <br />
                <br />
                In certain situations, such as mergers, acquisitions,
                reorganizations, asset sales, or similar corporate transactions,
                we may transfer or grant access to information to facilitate the
                assessment and execution of such transactions. If any business
                or assets are bought or sold, personal information may be
                transferred to third parties involved in the transaction.
                <br />
                <br />
                Our website/apps may include links to other websites/apps that
                may collect personally identifiable information. We are not
                responsible for the privacy policy or content of those linked
                websites/apps.
                <br />
                <br />
                <h6>How Long Do We Keep Your Information?</h6>
                In accordance with the Privacy Policy,
                TamilVokkaligaMatrimony.com will retain the information
                collected from users under the following circumstances:
                <br />
                <div style={{ padding: "10px" }}>
                  1. For the duration of users' subscription to our services,
                  meeting the intended purpose(s) for which it was collected.
                  <br />
                  2. To enforce agreements, conduct audits, resolve disputes,
                  establish legal defenses, and pursue legitimate business
                  activities.
                  <br />
                  3. To comply with relevant applicable laws.
                </div>
                This retention policy ensures the responsible and lawful
                handling of user information throughout its lifecycle.
                <br />
                <br />
                <h6>
                  What are the Security Precautions in respect of your personal
                  information?
                </h6>
                TamilVokkaligaMatrimony.com is committed to safeguarding your
                personal information with a combination of organizational and
                technical security measures. We have implemented internal
                control measures designed to ensure the security of any
                processed personal information. However, it's essential to note
                that we cannot guarantee the internet's absolute security. Once
                your information is in our possession, we strictly adhere to
                security guidelines to protect it against unauthorized access.
                <br />
                <br />
                <h6>Change of Privacy Policy</h6>We may change this Privacy
                Policy from time to time without any notice to you. However,
                changes will be updated in the Privacy Policy page.
                <br />
                <br />
                <h6>How to address your Grievance </h6>To address your grievance
                with TamilVokkaligaMatrimony.com, you can contact the Grievance
                Officer, Mr. Sundara Moorthy at the following details:
                <br />
                <br />
                <b>The Grievance Officer: </b>Mr. Sundara Moorthy
                <br />
                <b>Address: </b>37-G, Abirami Complex, First Floor, Vadakku
                Radha Veedhi, Dindigul-1.
                <br />
                <b>Contact Details: </b>Phone: 9865354499, 7639348617
                <br />
                <b>Email: </b>admin@tamilvokkaligamatrimony.com
                <br />
                <br />
                The Grievance Officer is available between 10 am to 6 pm IST
                from Monday to Saturday, excluding Sundays and Public Holidays
                in India.
                <br />
                <br />
                This appointment is in accordance with Section 5 (9) of the
                Information Technology (Reasonable Security & Procedures and
                Sensitive Personal Data or Information) Rule, 2011.
              </span>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={2}></Col>
      </Row>
    </Fragment>
  );
};

export default Privacy;
