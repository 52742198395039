import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../api_config";
import UserContext from "../Authcontext";
import Load from "../Loading/Load";
import "./Col.css";

function Contact() {
  const { user, token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city1, setCity1] = useState("");
  const [state1, setState1] = useState("");
  const [country1, setCountry1] = useState("");
  const [sameAddress, setSameAddress] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const navigate = useNavigate();

  const fetchData = (url, successCallback) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
        successCallback(sortedData);
      })
      .catch((error) => alert("Something error occured"));
  };

  useEffect(() => {
    fetchData(`${API_BASE_URL}/api/countries`, setCountries);
  }, []);

  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value;
    setSelectedCountry(selectedCountryId);
    fetchData(`${API_BASE_URL}/api/states/${selectedCountryId}`, setStates);
  };

  const handleStateChange = (event) => {
    const selectedStateId = event.target.value;
    setSelectedState(selectedStateId);
    fetchData(`${API_BASE_URL}/api/cities/${selectedStateId}`, setCities);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await axios.post(
      `${API_BASE_URL}/api/Contact`,
      {
        user,
        address,
        address1,
        city,
        state,
        country,
        address2,
        address3,
        city1,
        state1,
        country1,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    if (response.data.auth) {
      setLoading(false);
      navigate("/personal-info", { replace: true });
      setAddress("");
      setAddress1("");
      setCity("");
      setState("");
      setCountry("");
      setAddress2("");
      setAddress3("");
      setCity1("");
      setState1("");
      setCountry1("");
    }
  };

  const handleCheckboxChange = () => {
    setSameAddress(!sameAddress);
    if (!sameAddress) {
      setAddress2(address);
      setAddress3(address1);
      setCity1(city);
      setState1(state);
      setCountry1(country);
    } else {
      setAddress2("");
      setAddress3("");
      setCity1("");
      setState1("");
      setCountry1("");
    }
  };

  return loading ? (
    <Load />
  ) : (
    <div className="abc">
      <div className="page-wrapper bg-red p-t-180 p-b-100">
        <div className="wrapper wrapper--w960">
          <div className="card card-2">
            <div className="card-body">
              <h2 className="title">Contact Details</h2>
              <span className="req-des">All fields are mandatory</span>
              <form method="POST" onSubmit={handleSubmit}>
                <h3 className="h mb-3">Permanent Address</h3>
                <div className="input-group">
                  <input
                    value={address}
                    className="input--style-2"
                    type="text"
                    placeholder="Address line1 "
                    name="name"
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group">
                  <input
                    value={address1}
                    className="input--style-2"
                    type="text"
                    placeholder="Address 2"
                    name="name"
                    onChange={(e) => setAddress1(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group1">
                  <select
                    value={country}
                    id="country"
                    className="input--style-2 "
                    onChange={(e) => {
                      setCountry(e.target.value);
                      handleCountryChange(e);
                    }}
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={state}
                    disabled={!selectedCountry}
                    id="state"
                    className="input--style-2"
                    onChange={(e) => {
                      setState(e.target.value);
                      handleStateChange(e);
                    }}
                    required
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={city}
                    disabled={!selectedState}
                    id="city"
                    className="input--style-2  browser-default"
                    onChange={(e) => setCity(e.target.value)}
                    required
                  >
                    <option>Select City</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>

                <h3 className="h mb-3">Current Address</h3>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sameAddress"
                      checked={sameAddress}
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="sameAddress">
                      Same as permanent address
                    </label>
                  </div>
                </div>

                <div className="input-group">
                  <input
                    value={address2}
                    className="input--style-2"
                    type="text"
                    placeholder="Address line1 "
                    name="name"
                    onChange={(e) => setAddress2(e.target.value)}
                    disabled={sameAddress}
                  />
                </div>

                <div className="input-group">
                  <input
                    value={address3}
                    className="input--style-2"
                    type="text"
                    placeholder="Address 2"
                    name="name"
                    onChange={(e) => setAddress3(e.target.value)}
                    disabled={sameAddress}
                  />
                </div>

                <div className="input-group1">
                  <select
                    value={country1}
                    id="country1"
                    className="input--style-2  browser-default"
                    onChange={(e) => {
                      setCountry1(e.target.value);
                      handleCountryChange(e);
                    }}
                    disabled={sameAddress}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={state1}
                    id="state1"
                    className="input--style-2  browser-default"
                    onChange={(e) => {
                      setState1(e.target.value);
                      handleStateChange(e);
                    }}
                    disabled={sameAddress || !selectedCountry}
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={city1}
                    id="city1"
                    className="input--style-2  browser-default"
                    onChange={(e) => setCity1(e.target.value)}
                    disabled={sameAddress || !selectedState}
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="p-t-30">
                  <button className="btn btn--radius btn--green" type="submit">
                    Save & Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
