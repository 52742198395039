
import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import UserContext from './Authcontext'

export const PrivateRoute = () => {
  const { isAuthenticated } = useContext(UserContext);
  const location = useLocation();

  if (isAuthenticated && location.pathname.includes('/signup')) {
    return <Navigate to="/" replace />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/signup" />;
};

// export const VerifyRoute = () => {
//   const {user} = useContext(UserContext);

//   return user.verify === 0 ? <Navigate to="/user-under-verification"/> : <Outlet />
// }
