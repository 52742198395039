import { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <Fragment>
      <Row>
        <Col xs={12} lg={2}></Col>
        <Col xs={12} lg={8}>
          <div
            style={{
              border: "1px solid grey",
              margin: "20px",
              padding: "20px",
            }}
          >
            <h6>
              <u style={{ color: "grey" }}>
                <span style={{ color: "black" }}>
                  TERMS AND CONDITIONS OF MEMBERSHIP IN TAMILVOKKALIGAMATRIMONY
                </span>
              </u>
            </h6>
            <div style={{ padding: "10px" }}>
              <span style={{ fontSize: "12px" }}>
                Welcome to Tamilvokkaligamatrimony.com (herein referred to as
                BM).
                <br />
                <br />
                This document is an electronic record in terms of the
                Information Technology Act, 2000 and rules thereunder pertaining
                to electronic records as applicable and amended. This electronic
                record is generated by a computer system and does not require
                any physical or digital signatures.
                <br />
                <br />
                This document is published in accordance with the provisions of
                Rule 3 (1) of the Information Technology (Intermediaries
                Guidelines and Digital Media Ethics Code) Rules, 2021, which
                require publishing the rules and regulations, privacy policy,
                and the terms and conditions for access or usage of &nbsp;
                <Link to="https://tamilvokkaligamatrimony.com">
                  https://tamilvokkaligamatrimony.com
                </Link>
                &nbsp; (the "Website").
                <br />
                <br />
                PLEASE READ THE FOLLOWING TERMS AND CONDITIONS VERY CAREFULLY
                BEFORE USING THE WEBSITE. ACCESSING, BROWSING, OR OTHERWISE
                USING THE WEBSITE IMPLIES YOUR AGREEMENT TO BE BOUND BY ALL
                THESE TERMS AND CONDITIONS ("Agreement"). If you do not want to
                be bound by the Terms and Conditions, you must not use the
                Website or Tamilvokkaligamatrimony.com. The Terms and Conditions
                also include the applicable policies, which are incorporated
                herein by way of reference and as amended from time to time (the
                "Terms and conditions").
                <br />
                <br />
                <h6>Eligibility:</h6>
                <div style={{ padding: "10px" }}>
                  Tamilvokkaligamatrimony.com Membership and rights of admission
                  are reserved solely for: I. Indian Nationals & Citizens. II.
                  Persons of Indian Origin (PIO). III. Non-Resident Indians
                  (NRI). IV. Persons of Indian Descent or Indian Heritage. V.
                  Persons who intend to marry persons of Indian Origin. B)
                  Further, in the capacity as a Tamilvokkaligamatrimony.com
                  member, you confirm that you are: I. 18 years or above (if you
                  are a woman) or 21 years or above (if you are a man); II. If
                  you have applied for divorce, you may register on our
                  website/App by stating "Awaiting Divorce." III. If you are a
                  resident of any other country, you are legally competent to
                  marry as per the local rules applicable to your country, and
                  you shall comply with the Indian laws for marrying a person of
                  Indian Origin. C) In case you are a Registrant of the
                  prospective bride/bridegroom and have created a profile on the
                  Tamilvokkaligamatrimony.com Website/App on behalf of them or
                  are accessing the Website/App on their behalf, it implies that
                  you have taken their consent for their profile creation and
                  for accessing the Tamilvokkaligamatrimony.com Website/App.
                </div>
                <br />
                <h6>Registration:</h6>
                <div style={{ padding: "10px" }}>
                  We expect that you would complete the online registration
                  process with fairness and honesty by furnishing true,
                  accurate, current, complete information and providing recent
                  photos of yourself that will help you meet your parameters on
                  Tamilvokkaligamatrimony.com. We expect you to read the
                  relevant columns before entering details, selecting options,
                  or uploading photos. Please refrain from entering profile
                  details in fields other than the applicable ones (including
                  mentioning IDs of other platforms/websites/apps or repeating
                  your details in other fields after filling them once in the
                  relevant fields or using others' photographs).
                  <br />
                  <br />
                  In order to serve you better, if Tamilvokkaligamatrimony.com
                  requires additional details, you agree to provide them. You
                  may also give a missed call to any of our retail outlets'
                  phone numbers for us to call you back and assist you in
                  getting your profile registered on our website/app.
                  Furthermore, you may fill in your email ID and phone number in
                  any of our registration campaigns appearing on various
                  websites as part of our advertisements. This way, you may
                  receive a call from our Customer Service Center to assist you
                  in getting your profile registered on our website/app.
                  <br />
                  <br />
                  Registration of duplicate profiles of the same person is not
                  allowed on Tamilvokkaligamatrimony.com Website/App.
                  Tamilvokkaligamatrimony.com shall have full right to suspend
                  or terminate (without any notice) such duplicate profiles.
                  <br />
                  <br />
                  You acknowledge and confirm that your registration with
                  Tamilvokkaligamatrimony.com and the usage of its services are
                  with the bona fide intention of marriage and not otherwise.
                  Tamilvokkaligamatrimony.com Membership is restricted strictly
                  to the registered individual member only. Organizations,
                  companies, businesses, and/or individuals carrying on similar
                  or competitive business cannot become Members of
                  Tamilvokkaligamatrimony.com and nor use the
                  Tamilvokkaligamatrimony.com Service or its members' data for
                  any commercial purpose, and Tamilvokkaligamatrimony.com
                  reserves its right to initiate appropriate legal action for
                  breach of these obligations.
                </div>
                <br />
                <h6>Account Security :</h6>
                <div style={{ padding: "10px" }}>
                  You are responsible for safeguarding the confidentiality of
                  your Tamilvokkaligamatrimony.com login credentials such as
                  your user ID, password, OTP, etc., and for restricting access
                  to your computer/mobile to prevent unauthorized access to your
                  account. We, as a company, do not ask for your password, and
                  you are cautioned not to share your password with any persons.
                  You agree to accept responsibility for all activities that
                  occur under your Tamilvokkaligamatrimony.com account.
                </div>
                <br />
                <h6>Role and Responsibility of TAMILVOKKALIGAMATRIMONY</h6>
                <div style={{ padding: "10px" }}>
                  A. Tamilvokkaligamatrimony.com reproduces your details once
                  you register on our website/app on an "as is as available"
                  basis and also shares your profile with other registered
                  Tamilvokkaligamatrimony.com members within the website(s).
                  <br />
                  <br />
                  B. Tamilvokkaligamatrimony.com's obligation is only to provide
                  an interface to its registered members to search for their
                  prospect themselves without any assistance.
                  <br />
                  <br />
                  C. The profile search conducted by any
                  Tamilvokkaligamatrimony.com member and the matches shown
                  thereof are automatically generated by
                  Tamilvokkaligamatrimony.com, based on the partner preference
                  set by you. In the event of a Tamilvokkaligamatrimony.com
                  member changing their partner preference on the Website/App,
                  then the automated system-generated prospect results of the
                  Website/App may also undergo a corresponding change.
                  <br />
                  <br />
                  D. Tamilvokkaligamatrimony.com does not prohibit any member
                  from sending interest to your profile or communicating with
                  you based on their partner preference. But you are at liberty
                  to deny their interest or proceed further if you are
                  interested.
                  <br />
                  <br />
                  E. Tamilvokkaligamatrimony.com cannot guarantee or assume
                  responsibility for any specific results from the use of the
                  data available from the Tamilvokkaligamatrimony.com service or
                  from other matrimonial websites, including community-based
                  websites.
                  <br />
                  <br />
                  F. Tamilvokkaligamatrimony.com shall safeguard sensitive user
                  information using security standards, authentication
                  mechanisms, access controls, and encryption techniques.
                  <br />
                  <br />
                  G. Tamilvokkaligamatrimony.com cannot guarantee the complete
                  protection of user data while it is in transit or prevent any
                  tampering of the data by a third party with malicious intent
                  before the data reaches the Tamilvokkaligamatrimony.com
                  servers.
                  <br />
                  <br />
                  H. Tamilvokkaligamatrimony.com has a special safety feature
                  for women called "Secure Connect," which enables women to take
                  control of their privacy and safety while searching for their
                  life partner. "Secure Connect" has a calling feature that lets
                  women in Tamilvokkaligamatrimony.com receive calls from men
                  who have premium membership without revealing their contact
                  numbers to them. It is up to the women to respond to the other
                  party, and Tamilvokkaligamatrimony.com has no control over the
                  same.
                  <br />
                  <br />
                  I. Tamilvokkaligamatrimony.com does not authenticate/endorse
                  any information on any profile, and hence, you as a user need
                  to verify the credentials and information provided by other
                  users.
                </div>
                <br />
                <h6>Customer Care / Customer Service</h6>
                <div style={{ padding: "20px" }}>
                  In the event you come across any concerns with our service,
                  please feel free to contact our Grievance Officer, Mr. Sundara
                  Moorthy, at the following details:
                </div>
                <div style={{ padding: "10px" }}>
                  The Grievance Officer: Mr. Sundara Moorthy Address: 37-G,
                  Abirami Complex, First Floor, Vadakku Radha Veedhi,
                  Dindigul-1. Contact Details: Phone: 9865354499, 7639348617
                  Email: admin@tamilvokkaligamatrimony.com
                </div>
                <br />
                <h6> Confidentiality</h6>
                <div style={{ padding: "10px" }}>
                  Any feedback you provide to Tamilvokkaligamatrimony.com shall
                  be deemed to be non-confidential. Tamilvokkaligamatrimony.com
                  shall be free to use such feedback/information on an
                  unrestricted basis. Further, by submitting the feedback, you
                  represent and warrant that (i) your feedback does not contain
                  confidential or proprietary information of yourself or third
                  parties; (ii) Tamilvokkaligamatrimony.com is not under any
                  obligation of confidentiality, express or implied, with
                  respect to the feedback; (iii) you are not entitled to any
                  compensation or reimbursement of any kind from
                  Tamilvokkaligamatrimony.com for the feedback under any
                  circumstances.
                </div>
                <br />
                <h6>Privacy of Membership</h6>
                <div style={{ padding: "10px" }}>
                  To protect your privacy and understand our practices as
                  amended from time to time, please read and follow our Privacy
                  Policy, which also governs your visit to
                  Tamilvokkaligamatrimony.com. The personal information/data
                  provided to us by you during the course of usage of
                  Tamilvokkaligamatrimony.com will be treated as confidential
                  and in accordance with the Privacy Policy and applicable laws
                  and regulations. If you object to your information being
                  transferred or used, please do not use the website.
                </div>
                <br />
                <h6>Disputes between Members</h6>A. Tamilvokkaligamatrimony.com
                members are solely responsible for communications (through any
                medium) with prospects or vice versa.
                Tamilvokkaligamatrimony.com expressly disclaims any
                responsibility or liability for any monetary transactions,
                exchanges, interactions, or passing of information between any
                Tamilvokkaligamatrimony.com members via e-mail, chat,
                interaction, WhatsApp, or any other medium of communication,
                either using the website/app or otherwise.
                <br />
                <br />
                B. Tamilvokkaligamatrimony.com has no obligation to monitor any
                such disputes arising between its members, and it shall not be a
                party to any such dispute/litigation, etc.
                <br />
                <br />
                C. Tamilvokkaligamatrimony.com is not a broker or agent for any
                of its members, and it does not partake in the exchange of any
                kind of discussion between its members and prospects or the
                results of their discussions.
                <br />
                <br />
                <h6>Content Right</h6>A. You agree that all content of
                Tamilvokkaligamatrimony.com belongs to the service provider,
                excluding your or third-party content, including advertisements
                on the Website/App for which Tamilvokkaligamatrimony.com has the
                requisite license/rights to display on its Website/App.
                <br />
                <br />
                B. You acknowledge that you alone are responsible and liable for
                the content (mandatory or optional content you provided on our
                website) and information, including profile details,
                photographs, and other content(s) keyed in the relevant field of
                the Website/App at the time of the creation of the
                Tamilvokkaligamatrimony.com profile or subsequently modified by
                you. Also, for all the posts and communication, including
                personalized messages sent by Tamilvokkaligamatrimony.com paid
                members, with other member(s). You hereby represent and warrant
                that you own all rights, title, and interest in your
                content/information or have the necessary licenses, rights,
                consents, and permissions to provide the said
                content/information. However, by submitting the
                content/information to Tamilvokkaligamatrimony.com, you hereby
                grant Tamilvokkaligamatrimony.com a worldwide, non-exclusive,
                royalty-free, sub-licensable, and transferable license to use,
                reproduce, distribute, prepare derivative works of, display,
                publish, communicate to the public, law enforcement agencies,
                courts, and Tamilvokkaligamatrimony.com's business and services,
                including without limitation for promoting, communicating to the
                public, and redistributing part or all of the
                content/information (and derivative works thereof) in any media
                formats and through any media channels.
                <br />
                <br />
                C. While Tamilvokkaligamatrimony.com does not accept any
                obligation to monitor the content/information in your profile,
                if the content/information is found not to be in compliance with
                these Terms and conditions, Tamilvokkaligamatrimony.com may
                delete the content/information and/or terminate or suspend your
                registration (without a refund of any subscription paid by you
                in terms of clause 2 (b) of these terms and conditions). You
                agree that if the situation warrants,
                Tamilvokkaligamatrimony.com may, at its sole discretion, put
                restrictions on any Tamilvokkaligamatrimony.com member's
                communications with other members.
                <br />
                <br />
                D. The service provider reserves the right to proceed/initiate
                appropriate steps under the prevailing law against infringement
                by violators of its proprietary rights or for illegally copying,
                modifying, publishing, transmitting, distributing, performing,
                displaying, or selling any such proprietary information,
                including using it for commercial purposes in any manner
                whatsoever.
                <br />
                <br />
                <h6> Limitation of Liability</h6>A. Tamilvokkaligamatrimony.com
                or its office bearers shall, under no circumstances, be liable
                or responsible to the member or their authorized representative
                or registrant or any third party for any direct, indirect,
                special, exemplary, incidental, or consequential damages of any
                character, including, without limitation, damages resulting from
                the use of our Website/App/Third Party
                Website/Tamilvokkaligamatrimony.com services.
                <br />
                <br />
                B. Tamilvokkaligamatrimony.com informs you that the exchange of
                profiles through or by Tamilvokkaligamatrimony.com should not in
                any way be construed as a matrimonial offer and/or
                recommendation and/or advice or guarantee given to the member,
                from/by Tamilvokkaligamatrimony.com.
                <br />
                <br />
                C. Notwithstanding anything to the contrary contained herein,
                Tamilvokkaligamatrimony.com's liability to you for any cause
                whatsoever, and regardless of the form of the action, will at
                all times be limited to the amount paid, if any, by you to
                Tamilvokkaligamatrimony.com for any specific paid package, and
                no further.
                <br />
                <br />
                <b>
                  Tamilvokkaligamatrimony.com will not be liable in case of any
                  wrong/improper match made due to unavailability of profiles
                  from Tamilvokkaligamatrimony.com's owned other websites.
                  <br />
                  <br />
                  Tamilvokkaligamatrimony.com or its office bearers shall, under
                  no circumstances, be liable, if any, for the member entering
                  into financial transactions with any other
                  Tamilvokkaligamatrimony.com member or any third party.
                  <br />
                  <br />
                  Tamilvokkaligamatrimony.com or its office bearers shall, under
                  no circumstances, be liable, if any, for any members not
                  responding/reciprocating when you approach them for
                  matrimonial alliance.
                </b>
                <br />
                <br />
                <h6>Limitation of Liability</h6>A. Tamilvokkaligamatrimony.com
                or its office bearers shall, under no circumstances, be liable
                or responsible to the member or their authorized representative
                or registrant or any third party for any direct, indirect,
                special, exemplary, incidental, or consequential damages of any
                character, including, without limitation, damages resulting from
                the use of our Website/App/Third Party
                Website/Tamilvokkaligamatrimony.com services.
                <br />
                <br />
                Tamilvokkaligamatrimony.com informs you that the exchange of
                profiles through or by Tamilvokkaligamatrimony.com should not in
                any way be construed as a matrimonial offer and/or
                recommendation and/or advice or guarantee given to the member,
                from/by Tamilvokkaligamatrimony.com.
                <br />
                <br />
                Notwithstanding anything to the contrary contained herein,
                Tamilvokkaligamatrimony.com's liability to you for any cause
                whatsoever, and regardless of the form of the action, will at
                all times be limited to the amount paid, if any, by you to
                Tamilvokkaligamatrimony.com for any specific paid package, and
                no further.
                <br />
                <br />
                Notwithstanding anything to the contrary contained herein,
                Tamilvokkaligamatrimony.com's liability to you for any cause
                whatsoever, and regardless of the form of the action, will at
                all times be limited to the amount paid, if any, by you to
                Tamilvokkaligamatrimony.com for any specific paid package, and
                no further.
                <br />
                <br />
                <h6>Class Action Suits</h6>You acknowledge and confirm that you
                will not bring or participate in any class action or other class
                proceeding in connection with any dispute with
                Tamilvokkaligamatrimony.com. Further, neither you nor
                Tamilvokkaligamatrimony.com agrees to class arbitration.
                <br />
                <br />
                <h6>General</h6>In the event you file a false complaint against
                another prospect on our Website/App and consequently we have
                suspended/deleted that prospect's profile based on your
                complaint, then we reserve our right to initiate appropriate
                legal (Civil/Criminal) action against you and claim any and all
                costs expenses from you, for such
                irresponsible/misrepresentation/illegal/unlawful action. We also
                reserve our right to suspend your profile and forfeit any and
                all amounts paid by you for the Tamilvokkaligamatrimony.com
                services as per clause 2 (b) of these terms and conditions.
                <br />
                <br />
                Notwithstanding anything contained herein,
                Tamilvokkaligamatrimony.com reserves the absolute right to
                delete, in any manner as it deems fit, any content of any
                profile listing placed on
                Tamilvokkaligamatrimony.com/website/App (once such instance
                comes to Tamilvokkaligamatrimony.com's notice) in order to
                ensure proper consent has been obtained by you, prima facie
                accuracy, and the prevailing laws in force for the time being,
                especially those relating to providing any obscene, libelous,
                blasphemous, slanderous, defamatory, or invasive of another
                person's (deceased or alive) right of privacy or publicity. Or
                that may reasonably be deemed to be harmful, vulgar,
                pornographic, abusive, harassing, threatening, hateful,
                objectionable with respect to race, religion, creed,
                nationality, gender or otherwise unfit for reproduction; or
                suggests or encourages unfair or illegal/indecent, unlawful
                activity. Tamilvokkaligamatrimony.com also reserves the right to
                block/delete/suspend the profile which might be offensive,
                illegal or that might violate the rights, harm, or threaten the
                safety of our office bearers/employees (including undue
                communication with any employee) and/or other registered
                prospects or using our Tamilvokkaligamatrimony.com website/App
                as a means of communication while sending messages to other
                Tamilvokkaligamatrimony.com members regarding their profile
                identity in other matrimonial websites.
                <br />
                <br />
                <b>
                  21. Once your paid membership expires, you cannot avail the
                  unexpired balance phone call count/ unexpired SMS. Similarly,
                  you cannot access the already viewed
                  Tamilvokkaligamatrimony.com member(s) contact information
                  unless you renew your account within 30 days.
                </b>
                <br />
                <br />
                <b>
                  22. However, on renewal, the unexpired phone call / SMS shall
                  be carried forward to your account from the last day of
                  expiry.
                </b>
                <br />
                <br />
                <b>
                  23. In case of conflict between the terms and condition of
                  Website/App the of Tamilvokkaligamatrimony.com Website/App
                  shall prevail for the service provided through this
                  Website/App.
                </b>
                <br />
                <br />
                <h6>24. Disclaimer</h6>A. Your access to and use of the
                Tamilvokkaligamatrimony.com Services or any content is at your
                own risk. YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE
                PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS. WITHOUT
                LIMITING THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW,
                TAMILVOKKALIGAMATRIMONY.COM DISCLAIMS ALL WARRANTIES, EXPRESS OR
                IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                OR NON-INFRINGEMENT. Tamilvokkaligamatrimony.com does not
                warrant that the Website/App, its servers, or e-mail sent from
                Tamilvokkaligamatrimony.com are free of viruses or other harmful
                components. Tamilvokkaligamatrimony.com will not be liable for
                any damages of any kind arising from the use of this
                Tamilvokkaligamatrimony.com Website/App, including, but not
                limited to, direct, indirect, incidental, punitive, and
                consequential damages.
                <br />
                <br />
                B. Tamilvokkaligamatrimony.com does not give any implied or
                explicit guarantee or warranty of marriage or alliance by you
                choosing to register on our Website/App and using
                Tamilvokkaligamatrimony.com services (both paid and free).
                <br />
                <br />
                C. Notwithstanding anything contrary contained anywhere, under
                no circumstances, Tamilvokkaligamatrimony.com shall be held
                responsible or liable whatsoever or howsoever, arising out of,
                relating to or connected with:
                <div style={{ padding: "10px" }}>
                  i. any act or omission done by Tamilvokkaligamatrimony.com
                  /payment gateway/alliance partner etc.;
                  <br />
                  ii. any untrue or incorrect information submitted by you or on
                  your behalf;
                  <br />
                  iii. any decision taken by you or on your behalf or any
                  consequences thereof, based on any information provided by any
                  other user (including suspension/deletion of the profile from
                  Tamilvokkaligamatrimony.com);
                  <br />
                  iv. any unauthorized or illegal act done by any third party
                  relating to or connected with any information submitted by you
                  or on your behalf;
                  <br />
                  v. any cyber crime attempted or committed by anyone and
                  <br />
                  vi. any incident of force-majeure or 'act of god'.
                  <br />
                  vii. Any issue already stated in these terms and conditions
                  including the limitation of liability clause of these terms
                  and conditions.
                  <br />
                  viii. Using/availing of third-party services namely horoscope
                  matching services, etc. and for paying them through the
                  Website.
                  <br />
                  ix. Tamilvokkaligamatrimony.com shall not be liable for the
                  outcome of any interaction in a meeting, call, SMS, chat,
                  email, or social media posts at any point in time
                  <br />
                  x. Any issues relating to any technical malfunction of any
                  telecommunication network, software, hardware failures,
                  network congestion, denial of service, failure due to
                  spamming, or any combination of the above.
                </div>
                D. You expressly agree that your use of this Website/App is at
                your sole risk.
                <br />
                <br />
                E. We are not liable to you for any damage or alteration to your
                equipment, including but not limited to computer equipment,
                hand-held devices, or mobile telephones, as a result of the
                installation or use of the app. We are not liable to you for any
                damage or alteration to your equipment, including but not
                limited to computer equipment, hand-held devices, or mobile
                telephones, as a result of the installation or use of the app.
                <br />
                <br />
                <h6>25. Indemnity</h6>By using our Tamilvokkaligamatrimony.com
                services you agree to defend, indemnify, and hold harmless
                Tamilvokkaligamatrimony.com, its subsidiaries, affiliates,
                Directors, officers, agents, and other partners and employees,
                fully indemnified and harmless from any loss, damage, liability,
                claim, or demand, including reasonable attorney's fees, made by
                any person through improper use of the service provided by
                Tamilvokkaligamatrimony.com. This defense and indemnification
                obligation will survive in perpetuity.
                <br />
                <br />
                <h6>Termination</h6>A. We/ Tamilvokkaligamatrimony.com, in good
                faith, reserve our right to terminate your
                Tamilvokkaligamatrimony.com membership or suspend/delete your
                profile at our sole discretion without any notice to you and
                take any action as mentioned in clause 2 (b) of the Terms and
                Conditions, in case you
                <br />
                <div style={{ padding: "10px" }}>
                  I. seek physical / financial favors from our other registered
                  members or
                  <br />
                  II. have provided incorrect information on our website/App or
                  <br />
                  III. have committed any fraudulent/ unlawful/ illegal
                  activities through the use of our website/App or
                  <br />
                  IV. have violated these Terms and conditions or
                  <br />
                  V. have misappropriated the name, likeness, email address,
                  contact no, or other personally identifiable information of
                  another Tamilvokkaligamatrimony.com member created a profile
                  on our website /App by impersonation/ fake/ bogus/ false/
                  misrepresentation/ without the consent of the person whose
                  profile is being registered or use only part information,
                  including using a photo of third parties without the
                  permission of such third parties or act with other
                  members/employees in an indecent/improper manner,
                </div>
                B. If Tamilvokkaligamatrimony.com receives any complaint against
                you and consequently we have suspended/deleted your profile in
                good faith, then we shall not be liable to you in any manner
                whatsoever, including for any loss, costs, expenses, or
                consequence, if any.
                <br />
                <br />
                C. Unless otherwise provided in these Terms and Conditions,
                Tamilvokkaligamatrimony.com membership may be terminated by
                Tamilvokkaligamatrimony.com / you without assigning any reasons
                by serving 7 ( seven) days written notice on the other, all
                amounts paid till date by you to Tamilvokkaligamatrimony.com
                shall be forfeited.
                <br />
                <br />
                D. Upon termination/suspension of Tamilvokkaligamatrimony.com
                membership, your right to use or access the
                Tamilvokkaligamatrimony.com Website/App
                Tamilvokkaligamatrimony.com services will cease immediately. All
                provisions of these terms and conditions that by their nature
                should survive termination shall survive termination, including,
                without limitation, disclaimers, and limitations of liability.
                Termination of your access to and use of the
                Tamilvokkaligamatrimony.com Services shall not relieve you of
                any obligations arising or accruing prior to such termination.
              </span>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={2}></Col>
      </Row>
    </Fragment>
  );
};

export default Terms;
