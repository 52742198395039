import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { PrivateRoute } from "./Components/PrivateRoute";
import UserContext from "./Components/Authcontext";

import Cool from "./Components/Details/Cool";
import Contact from "./Components/Details/Contact";
import PersonalDetails from "./Components/Details/PersonalDetails";
import FamilyDetails from "./Components/Details/FamilyDetails";
import HabitDetails from "./Components/Details/HabitsDetail";
import OtpForm from "./Components/Details/Otp/Otp";
import ReligiousInformation from "./Components/Details/ReligiousInformation";
import PartnerPreferenceDetails from "./Components/Details/PartnerPreferenceDetails";
import Register from "./Components/Register/Register";
import Footer from "./Components/Foote/Footer";
import Home from "./Components/Home/Home";
import Header from "./Components/Header/Header";
import ViewProfile from "./Components/Vp/ViewProfile";
import EditProfile from "./Components/EditPro/EditProfile";
import BasicDetails1 from "./Components/EditPro/BasicDetails1";
import EditPreference from "./Components/EditPro/EditPP";
import Image from "./Components/Details/Image";
import SearchBox from "./Components/Search/Search";
import Mailbox from "./Components/Mailbox/Mailbox";
import HoroscopeBoxes from "./Components/horo/Horo";
import Side from "./Components/Admin/Header/Side";
import AdminLogin from "./Components/Admin/Login/Login";
import AdminRoute from "./Components/Admin/AdminRoute";
import AdminVp from "./Components/Admin/AdminVp";
import MyForm from "./Components/Details/MyForm";
import HomeAllTotalMatch from "./Components/Home/HomeAllTotalMatch";
import HomeAllDailyRecom from "./Components/Home/HomeAllDailyRecom";
import HomeAllPartPref from "./Components/Home/HomeAllPartPref";

import Scroll from "./Components/Scroll";

import "./App.css";
import StripePayment from "./Components/Payment/payment/StripePayment";
import Cancel from "./Components/Payment/payment/cancel";
import Success from "./Components/Payment/payment/success";
import Femail from "./Components/ForgotPass/Femail";
import Fotp from "./Components/ForgotPass/Fotp";
import FPass from "./Components/ForgotPass/FPass";
import PreLoader from "./Components/PreLoader";
import UserVerification from "./Components/UserVerification";
import Privacy from "./Components/Register/Privacy";
import Terms from "./Components/Register/Terms";

function App() {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading) {
    return (
      <div className="loading-screen">
        <PreLoader />
      </div>
    );
  }

  return (
    <div className="app">
      <Scroll />
      <Routes>
        <Route
          path="/signup"
          element={
            <>
              <Register />
              <Footer />
            </>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <>
              <Privacy />
              <Footer />
            </>
          }
        />
        <Route
          path="/terms-conditions"
          element={
            <>
              <Terms />
              <Footer />
            </>
          }
        />
        <Route exact path="/login/identify" element={<Femail />} />
        <Route exact path="/login/identify/otp" element={<Fotp />} />
        <Route exact path="/login/identify/reset" element={<FPass />} />
        <Route element={<PrivateRoute />}>
          <Route exact path="/basic-info" element={<Cool />} />
          <Route exact path="/contact-info" element={<Contact />} />
          <Route exact path="/personal-info" element={<PersonalDetails />} />
          <Route exact path="/family-info" element={<FamilyDetails />} />
          <Route
            exact
            path="/religious-Info"
            element={<ReligiousInformation />}
          />
          <Route exact path="/habit-info" element={<HabitDetails />} />
          <Route
            exact
            path="/partner-preference-info"
            element={<PartnerPreferenceDetails />}
          />
          <Route exact path="/image" element={<Image />} />
          <Route exact path="/otp" element={<MyForm />} />
          <Route exact path="/otp-verify" element={<OtpForm />} />
          <Route
            exact
            path="/user-under-verification"
            element={<UserVerification />}
          />
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/matches/total-matches"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />{" "}
                <HomeAllTotalMatch />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/matches/daily-recommendations"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />{" "}
                <HomeAllDailyRecom />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/matches/partner-preferences"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />{" "}
                <HomeAllPartPref />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/view-profile"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />
                <ViewProfile />
                <Footer />
              </>
            }
          />

          <Route exact path="/EditProfile" element={<EditProfile />} />
          <Route
            exact
            path="/searchBox"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />
                <SearchBox />
                <Footer />
              </>
            }
          />

          <Route
            exact
            path="/MailBox"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />
                <Mailbox />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/payment/paymentoption"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />
                <StripePayment />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/payment/cancel"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />
                <Cancel />
                <Footer />
              </>
            }
          />
          <Route
            exact
            path="/payment/success"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />
                <Success />
              </>
            }
          />
          <Route
            exact
            path="/edit-profile"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />{" "}
                <BasicDetails1 />
                <Footer />
              </>
            }
          />

          <Route
            exact
            path="/edit-partner-preferences"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />{" "}
                <EditPreference />
                <Footer />
              </>
            }
          />

          <Route
            exact
            path="/horoscope"
            element={
              <>
                <Header file={user.filename} name={user.first_name} />{" "}
                <HoroscopeBoxes />
                <Footer />
              </>
            }
          />
        </Route>

        <Route element={<AdminRoute />}>
          <Route exact path="/admin" element={<Side />} />
          <Route exact path="/admin-view-user-prof" element={<AdminVp />} />
        </Route>
        <Route exact path="/admin-signin" element={<AdminLogin />} />
      </Routes>
    </div>
  );
}

export default App;
