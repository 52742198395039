import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import OtpInput from "react-otp-input";
import { API_BASE_URL } from "../api_config";

import "./forgotpass.css";
import ForgotPassContext from "./ForgotPassContext";

const Fotp = () => {
  const { userData } = useContext(ForgotPassContext);
  const [code, setCode] = useState("");

  const handleChange = (code) => setCode(code);

  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/email-verify-otp`,
        {
          id: userData.userId,
          otpcode: code,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        navigate("/login/identify/reset");
      }
    } catch (error) {
      alert("Something error occured")
    }
  }

  async function handleResendOTP(e) {
    e.preventDefault();
    try {
      await axios.put(
        `${API_BASE_URL}/api/email/re-send-email`,
        {
          id: userData.userId,
          email: userData.email,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  const renderInput = (inputProps, index) => (
    <input key={index} type="text" maxLength="1" {...inputProps} />
  );

  return (
    <div className="ball">
      <div className="otp-form-wrapper">
        <div className="otp-form-container">
          <h2>Enter OTP</h2>
          <form onSubmit={handleSubmit}>
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={4}
              separator={<span style={{ width: "8px" }}>-</span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "1px solid transparent",
                borderRadius: "8px",
                width: "54px",
                height: "54px",
                fontSize: "16px",
                fontWeight: "500",
                color: "#000",
                caretColor: "blue",
                margin: "0px 5px 10px 5px",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
              renderInput={renderInput}
            />
            <button className="otp-btn" type="submit">
              verify
            </button>
            <button
              className="otp-resend-btn"
              type="button"
              onClick={handleResendOTP}
            >
              Resend OTP
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Fotp;
