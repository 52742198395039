import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { API_BASE_URL } from "../../api_config";
import axios from "axios";
import "./maleProfiles.css";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";

const itemsPerPage = 10;

const MaleProfiles = () => {
  const [maleUsers, setMaleUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nameFilter, setNameFilter] = useState("");
  const [showDelModal, setShowDelModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [delId, setDelId] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const getMaleUsers = useCallback(() => {
    axios
      .get(`${API_BASE_URL}/api/admin/male-users`, {
        withCredentials: true,
      })
      .then((res) => {
        const filteredUsers = nameFilter
          ? res.data.filter(
              (user) =>
                user.first_name
                  .toLowerCase()
                  .includes(nameFilter.toLowerCase()) ||
                user.last_name
                  .toLowerCase()
                  .includes(nameFilter.toLowerCase()) ||
                user.mobile.includes(nameFilter)
            )
          : res.data;
        setMaleUsers(filteredUsers);
      })
      .catch((err) => alert("Something error occurred"));
  }, [nameFilter, setMaleUsers]);

  useEffect(() => {
    getMaleUsers();
  }, [nameFilter, currentPage, getMaleUsers]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = maleUsers.slice(indexOfFirstItem, indexOfLastItem);

  const columns = [
    { id: "id", label: "ID", minWidth: 50 },
    { id: "image", label: "Image", minWidth: 100 },
    { id: "name", label: "Name", minWidth: 170 },
    { id: "gender", label: "Gender", minWidth: 100 },
    { id: "mobile", label: "Mobile", minWidth: 100 },
    { id: "email", label: "Email", minWidth: 150 },
    { id: "raasi", label: "Raasi", minWidth: 100 },
    { id: "star", label: "Star", minWidth: 100 },
    { id: "dosham", label: "Dosham", minWidth: 150 },
    { id: "viewProfile", label: "View Profile", minWidth: 100 },
    { id: "delete", label: "Delete", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
  ];

  const handleStatus = (userId, isInactive) => {
    const updatedData = { user_id: userId, isInactive };

    axios
      .post(`${API_BASE_URL}/api/admin/updateUserStatus`, updatedData, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.message) {
          getMaleUsers();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (userId) => {
    // console.log("userId Del: " + userId);
    setDelId(userId);
    setShowDelModal(true);
  };

  const handleClose = () => {
    setShowDelModal(false);
    setShowAlertModal(false);
  };

  const handleSubmitAlert = useCallback(
    async (event) => {
      event.preventDefault();
      const clickedButton = event.nativeEvent.submitter;
      const buttonValue = clickedButton.getAttribute("name");

      if (buttonValue === "Yes") {
        console.log("Yes button clicked and id is: " + delId);
        try {
          const res = await axios.post(`${API_BASE_URL}/api/delUserId`, {
            user_id: delId,
          });
          if (res.data.message) {
            // console.log(res.data.message);
            setAlertMessage("User deleted successfully");
          } else if (res.data.error) {
            // console.log(res.data.error);
            setAlertMessage("Error: " + res.data.error);
          }
        } catch (err) {
          console.log(err);
        }
        setShowDelModal(false);
        setShowAlertModal(true);
        getMaleUsers();
      } else if (buttonValue === "No") {
        handleClose();
      }
    },
    [delId, getMaleUsers]
  );

  return (
    <div className="verified-profiles-container">
      <h2 className="verified-profiles-heading">Male Profiles Page</h2>
      <div className="name-filter">
        <input
          type="text"
          placeholder="Filter by name or mobile"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
        />
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ tableLayout: "auto" }}
          >
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    fontSize: "1.25rem",
                    color: "white",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      backgroundColor: "#b63333",
                      border: "1px solid black",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems.map((item, index) => (
                <TableRow hover role="checkbox" key={index}>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    {item.user_id}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    <img
                      src={`${API_BASE_URL}/uploads/` + item.filename}
                      alt={`${item.first_name}`}
                      style={{
                        width: "125px",
                        height: "150px",
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    {item.first_name} {item.last_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    {item.gender}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    {item.mobile}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    {item.email}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    {item.raasi}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    {item.star}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    {item.naga_dosham === "naga"
                      ? item.chevvai_dosham === "chevvai"
                        ? "Naga_dosham, "
                        : "Naga_dosham"
                      : ""}
                    {item.chevvai_dosham === "chevvai" ? "Chevvai_dosham" : ""}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    <Link
                      to={"/admin-view-user-prof"}
                      state={{ userId: item.user_id }}
                      className="action-btn"
                    >
                      View
                    </Link>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    <button
                      type="button"
                      className="action-btn"
                      onClick={() => handleDelete(item.user_id)}
                    >
                      Delete
                    </button>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "0.5px solid black" }}
                  >
                    <button
                      type="button"
                      className="action-btn"
                      onClick={() =>
                        handleStatus(item.user_id, item.isInactive)
                      }
                    >
                      {item.isInactive === 0 ? "Active" : "Inactive"}
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={maleUsers.length}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
        />
      </Paper>
      <Modal show={showDelModal} onHide={handleClose}>
        <Modal.Header className="bg-info" closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Form onSubmit={handleSubmitAlert}>
            <Row>
              <Col xs={12} className="text-center">
                Confirm Delete?
              </Col>
              <Col xs={12}>
                <Button type="submit" name="Yes" className="m-2">
                  Yes
                </Button>
                <Button type="submit" name="No" className="bg-danger">
                  No
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showAlertModal} onHide={handleClose}>
        <Modal.Header className="bg-info" closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Form onSubmit={handleClose}>
            <Row>
              <Col xs={12} className="text-center">
                {alertMessage}
              </Col>
              <Col xs={12}>
                <Button type="submit" className="mt-2">
                  ok
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MaleProfiles;
