import React, { useState, useEffect, useContext } from "react";
import UserContext from "../Authcontext";
import axios from "axios";
import { API_BASE_URL } from "../api_config";
import { Link, useLocation } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

export default function HomePartPref() {
  const { user } = useContext(UserContext);
  const [partPref, setPartPref] = useState([]);

  const partPrefPath = useLocation();

  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/api/part-pref`,
        {
          params: {
            id: user.user_id,
            gender: user.gender,
            membership: user.pay_membership,
            age: user.age,
            maritalStatus: user.marital_status,
            language: user.mother_tongue,
            edu: user.education,
            empType: user.employment_type,
            occu: user.occupation,
            income: user.income,
            religion: user.religion,
            caste: user.caste,
            kulam: user.kulam,
            star: user.star,
            raasi: user.raasi,
            height: user.height,
            weight: user.weight,
            bodyType: user.body_type,
            complexion: user.complexion,
            physicalStatus: user.physical,
          },
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (Array.isArray(res.data)) {
          // console.log("part-pref: " + JSON.stringify(res.data));
          setPartPref(res.data);
        }
      })
      .catch((err) => {});
  }, [user]);

  const renderPartPref = () => {
    return partPref.map((prof, i) => (
      <SwiperSlide key={i}>
        <li className="prof-daily-recom-item">
          <Link
            to={"/view-profile"}
            state={{ userId: prof.user_id }}
            className="prof-daily-recom-item"
          >
            {prof.filename === null ? (
              <CgProfile className="prof-daily-recom-img" />
            ) : (
              <img
                src={`${API_BASE_URL}/uploads/` + prof.filename}
                alt={`${prof.first_name}`}
                className="prof-daily-recom-img"
                style={{ zIndex: 1 }}
              />
            )}
            <p className="prof-daily-recom-name">{prof.first_name}</p>
          </Link>
        </li>
      </SwiperSlide>
    ));
  };

  return (
    <div className="prof-daily-recom-container">
      <div className="prof-total-matches-heading">
        <h3 className="prof-daily-recom-heading">Partner Recommendations</h3>
        {partPrefPath.pathname === "/" && (
          <Link to="/matches/partner-preferences">See All</Link>
        )}
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        navigation={true}
        modules={[Navigation]}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        {renderPartPref()}
      </Swiper>
    </div>
  );
}
