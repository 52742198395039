import './text.css';
// import TimePicker from 'react-time-picker';
function Text({dob, place, meetingTime, period, handleDOBChange, handleTimeChange, handlePlaceChange, handlePeriodChange}) {

  const handleDOB = (event) => {
    handleDOBChange(event.target.value);
  }
  
  const handleTime = (event) => {
    handleTimeChange(event.target.value);
  };

  const handlePlace = (event) => {
    handlePlaceChange(event.target.value);
  }

  const handlePeriod = (event) => {
    handlePeriodChange(event.target.value);
  };

  return (
    <div className="text">
      <h1>Enter Your Information</h1>
      <label>Date of Birth:</label>
      <input type="date" value={dob} onChange={handleDOB} />

      <label>
        Time:
        <input type="time" value={meetingTime} onChange={handleTime} />
      </label>
      <label>
        Period:
        <select value={period} onChange={handlePeriod}>
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </label>
      <label>Place:</label>
      <input type="text" value={place} onChange={handlePlace} />
    </div>
  );
}

export default Text;