import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import Text from "../horo/Text";
import axios from "axios";
import UserContext from "../Authcontext";

import { API_BASE_URL } from "../api_config";

import "./horo.css";

function HoroscopeBoxes() {
  const { token } = useContext(UserContext);
  const [dob, setDOB] = useState("");
  const [place, setPlace] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState("")
  const [period, setPeriod] = useState("AM");

  const navigate = useNavigate();

  const options = [
    { value: "ல", label: "ல" },
    { value: "ரா", label: "ரா" },
    { value: "கே", label: "கே" },
    { value: "சூ", label: "சூ" },
    { value: "சனி", label: "சனி" },
    { value: "செ", label: "செ" },
    { value: "சுக்", label: "சுக்" },
    { value: "புதன்", label: "புதன்" },
    { value: "குரு", label: "குரு" },
    { value: "சந்திரன்", label: "சந்திரன்" },
    { value: "(மா )", label: "(மா )" },
    { value: "(வ )", label: "(வ )" },
  ];

  useEffect(() => {
    const fetchHoroscopeData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/horoscope`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        const horoscopeData = response.data[0];

        const selectedOptionsObj = {};
        for (let i = 1; i <= 24; i++) {
          const boxName = `box${i}`;
          const selectedOption = horoscopeData[boxName];
          if (selectedOption && selectedOption.length > 0) {
            selectedOptionsObj[i] = selectedOption.split(",").map((option) => ({
              value: option,
              label: option,
            }));
          }
        }
        function formatDOB(dob) {
          const dobDate = new Date(dob);
          const year = dobDate.getFullYear();
          const month = String(dobDate.getMonth() + 1).padStart(2, "0");
          const day = String(dobDate.getDate()).padStart(2, "0");
          const formattedDOB = `${year}-${month}-${day}`;
          return formattedDOB;
        }

        setSelectedOptions(selectedOptionsObj);
        setDOB(formatDOB(horoscopeData.date));
        setPlace(horoscopeData.place);
        setMeetingTime(horoscopeData.time);
        setPeriod(horoscopeData.period);
      } catch (error) {
        setError("An error occurred. Please try again.")
      }
    };

    fetchHoroscopeData();
  }, [token]);

  const handleDOBChange = (value) => {
    setDOB(value);
  };

  const handleTimeChange = (value) => {
    setMeetingTime(value);
  };

  const handlePlaceChange = (value) => {
    setPlace(value);
  };

  const handlePeriodChange = (value) => {
    setPeriod(value);
  };

  const handleChange = (selected, name) => {
    setSelectedOptions((prevSelectedOptionsMap) => ({
      ...prevSelectedOptionsMap,
      [name]: selected,
    }));
  };

  const getSelectedOptions = (name) => {
    return selectedOptions[name] || null;
  };

  const handleSaveProfile = async () => {
    const selectedOptionsObj = {};

    for (let i = 1; i <= 24; i++) {
      const boxName = `${i}`;
      const selectedOption = selectedOptions[boxName];

      if (selectedOption && selectedOption.length > 0) {
        selectedOptionsObj[boxName] = selectedOption.map(
          (option) => option.value
        );
      }
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/insertHoroscope`,
        { selectedOptionsObj, dob, place, meetingTime, period },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        navigate("/");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="back">
      <Text
        dob={dob}
        place={place}
        meetingTime={meetingTime}
        period={period}
        handleDOBChange={handleDOBChange}
        handleTimeChange={handleTimeChange}
        handlePlaceChange={handlePlaceChange}
        handlePeriodChange={handlePeriodChange}
      />
      <div className="horoscope-main-container">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <div className="horo-img">
            <div className="horoscope-container">
              <div className="horoscope-box">
                <Select
                  name="1"
                  options={options}
                  isMulti
                  value={getSelectedOptions("1")}
                  onChange={(selected) => handleChange(selected, "1")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="2"
                  options={options}
                  isMulti
                  value={getSelectedOptions("2")}
                  onChange={(selected) => handleChange(selected, "2")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="3"
                  options={options}
                  isMulti
                  value={getSelectedOptions("3")}
                  onChange={(selected) => handleChange(selected, "3")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="4"
                  options={options}
                  isMulti
                  value={getSelectedOptions("4")}
                  onChange={(selected) => handleChange(selected, "4")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="5"
                  options={options}
                  isMulti
                  value={getSelectedOptions("5")}
                  onChange={(selected) => handleChange(selected, "5")}
                />
              </div>
              <div className="horoscope-box"></div>

              <div className="horoscope-box"></div>
              <div className="horoscope-box">
                <Select
                  name="6"
                  options={options}
                  isMulti
                  value={getSelectedOptions("6")}
                  onChange={(selected) => handleChange(selected, "6")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="7"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("7")}
                  onChange={(selected) => handleChange(selected, "7")}
                />
              </div>
              <div className="horoscope-box"></div>
              <div className="horoscope-box"></div>
              <div className="horoscope-box">
                <Select
                  name="8"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("8")}
                  onChange={(selected) => handleChange(selected, "8")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="9"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("9")}
                  onChange={(selected) => handleChange(selected, "9")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="10"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("10")}
                  onChange={(selected) => handleChange(selected, "10")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="11"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("11")}
                  onChange={(selected) => handleChange(selected, "11")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="12"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("12")}
                  onChange={(selected) => handleChange(selected, "12")}
                />
              </div>
            </div>
            {/* <img className="gopi" src={mokka} alt="img" /> */}
          </div>
          <div className="horo-img">
            <div className="horoscope-container">
              <div className="horoscope-box">
                <Select
                  name="13"
                  options={options}
                  isMulti
                  value={getSelectedOptions("13")}
                  onChange={(selected) => handleChange(selected, "13")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="14"
                  options={options}
                  isMulti
                  value={getSelectedOptions("14")}
                  onChange={(selected) => handleChange(selected, "14")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="15"
                  options={options}
                  isMulti
                  value={getSelectedOptions("15")}
                  onChange={(selected) => handleChange(selected, "15")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="16"
                  options={options}
                  isMulti
                  value={getSelectedOptions("16")}
                  onChange={(selected) => handleChange(selected, "16")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="17"
                  options={options}
                  isMulti
                  value={getSelectedOptions("17")}
                  onChange={(selected) => handleChange(selected, "17")}
                />
              </div>
              <div className="horoscope-box"></div>
              <div className="horoscope-box"></div>
              <div className="horoscope-box">
                <Select
                  name="18"
                  options={options}
                  isMulti
                  value={getSelectedOptions("18")}
                  onChange={(selected) => handleChange(selected, "18")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="19"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("19")}
                  onChange={(selected) => handleChange(selected, "19")}
                />
              </div>
              <div className="horoscope-box"></div>
              <div className="horoscope-box"></div>
              <div className="horoscope-box">
                <Select
                  name="20"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("20")}
                  onChange={(selected) => handleChange(selected, "20")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="21"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("21")}
                  onChange={(selected) => handleChange(selected, "21")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="22"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("22")}
                  onChange={(selected) => handleChange(selected, "22")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="23"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("23")}
                  onChange={(selected) => handleChange(selected, "23")}
                />
              </div>
              <div className="horoscope-box">
                <Select
                  name="24"
                  options={options}
                  menuPlacement="top"
                  isMulti
                  value={getSelectedOptions("24")}
                  onChange={(selected) => handleChange(selected, "24")}
                />
              </div>
            </div>
            {/* <img className="gopi2" src={mokka} alt="img" /> */}
          </div>
        </div>
      </div>
      <div className="ga">
        <button
          className="btn btn-primary mb-4"
          type="submit"
          onClick={handleSaveProfile}
        >
          Save Profile
          <i className="uil uil-navigator"></i>
        </button>
        {error && <p>{error}</p>}
      </div>
    </div>
  );
}

export default HoroscopeBoxes;
