import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(
    () => Cookies.get("m_ad") === "true"
  );

  const login = () => {
    setIsAdminAuthenticated(true);
  };

  const logout = () => {
    Cookies.remove("m_ad");
    setIsAdminAuthenticated(false);
  };

  useEffect(() => {
    if (isAdminAuthenticated) {
      Cookies.set("m_ad", "true", {expires: 7});
    } else {
      Cookies.remove("m_ad");
    }
  }, [isAdminAuthenticated]);

  return (
    <AuthContext.Provider value={{ isAdminAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
