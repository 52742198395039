import { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";

import { API_BASE_URL } from "../api_config";
import { useNavigate } from "react-router-dom";
import UserContext from "../Authcontext";

import verImg from "../../assets/verification-img.png";
import comLogo from "../../assets/logo.png";

import "./myform.css";

export default function MyForm() {
  const { user, token } = useContext(UserContext);
  const [mob, setMob] = useState(user.userMob || "");
  const navigate = useNavigate();

  useEffect(() => {
    setMob(user.userMob || "");
  }, [user]);

  const sendMob = async () => {
    try {
      await axios
        .post(
          `${API_BASE_URL}/api/mob/sendMob`,
          { mob, name: user.name },
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          navigate("/otp-verify");
        });
    } catch (error) {
      alert("Something error occured");
    }
  };

  return (
    <div className="ver-otp-cont">
      <img src={verImg} alt="verification" />
      <Flex className="otp-form-cont" align="center" justify="center">
        <Stack
          className="form-otp-wrapper"
          spacing={8}
          mx="auto"
          maxW="lg"
          py={12}
          px={6}
        >
          <img src={comLogo} alt="Tamil Vokkaliga matrimony" />
          <Stack className="form-otp-container" align="center">
            <Heading as="h1" className="otp-heading" fontSize="xl">
              Verification
            </Heading>
            <Box
              rounded="lg"
              bg={useColorModeValue("white", "gray.700")}
              boxShadow="lg"
              p={8}
            >
              <Stack spacing={4}>
                <FormControl id="mob">
                  <FormLabel>Mobile number</FormLabel>
                  <Input
                    className="otp-input"
                    type="tel"
                    value={mob}
                    placeholder="Your Mobile number"
                    onChange={(e) => setMob(e.target.value)}
                  />
                </FormControl>

                <Stack spacing={10}>
                  <Button
                    className="otp-submit-btn"
                    bg="blue.400"
                    color="white"
                    _hover={{
                      bg: "blue.500",
                    }}
                    onClick={() => sendMob()}
                  >
                    Get OTP
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Flex>
    </div>
  );
}
