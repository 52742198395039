import { useContext } from "react";
import axios from "axios";
import { AiTwotoneHeart, AiOutlineHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { UserContext } from "../Authcontext";
import { API_BASE_URL } from "../api_config";

export default function SearchFilter({ filterData }) {
  const { token, likedProfiles, setLikedProfiles, fetchUserData } = useContext(UserContext);

  const isProfileLiked = (userId) => {
    return likedProfiles !== undefined && likedProfiles.includes(userId);
  };

  const handleLikeDislike = (userId) => {
    if (isProfileLiked(userId)) {
      // Dislike the profile
      const updatedLikedProfiles = likedProfiles.filter((id) => id !== userId);
      setLikedProfiles(updatedLikedProfiles);
      axios
        .put(
          `${API_BASE_URL}/api/dislike-profile/${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          fetchUserData()
        })
        .catch((error) => {
          // Handle error if needed
        });
    } else {
      // Like the profile
      const updatedLikedProfiles = [...likedProfiles, userId];
      setLikedProfiles(updatedLikedProfiles);
      axios
        .put(
          `${API_BASE_URL}/api/update-like-profiles`,
          { likedProfiles: updatedLikedProfiles },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          fetchUserData()
        })
        .catch((error) => {
          // Handle error if needed
        });
    }
  };

  function renderSearchFilterData() {
    return filterData.map((prof, i) => (
      <li className="profile-total-matches-item" key={i}>
        <div className="profile-total-match-blur">
          <div className="prof-total-match-cont">
            {prof.filename === null ? (
              <CgProfile className="prof-total-matches-img" />
            ) : (
              <img
                src={`${API_BASE_URL}/uploads/` + prof.filename}
                alt={`${prof.first_name}`}
                className="prof-total-matches-img"
              />
            )}
            <div className="prof-total-match-details">
              <h3>
                {prof.first_name} {prof.last_name}
              </h3>
              <p>age: {prof.age}</p>
              <p>education: {prof.education}</p>
              <p>occupation: {prof.occupation}</p>
              <p>
                {prof.city_name}, {prof.state_name}, {prof.country_name}
              </p>
            </div>
          </div>
        </div>
        <div className="match-intrst-cont-btns">
          {isProfileLiked(prof.user_id) ? (
            <AiTwotoneHeart
              className="match-intrst-btn"
              color="red"
              size={35}
              onClick={() => handleLikeDislike(prof.user_id)}
            />
          ) : (
            <AiOutlineHeart
              className="match-intrst-btn"
              color="white"
              size={35}
              onClick={() => handleLikeDislike(prof.user_id)}
            />
          )}
          <Link
            to={"/view-profile"}
            state={{ userId: prof.user_id }}
            className="total-match-view-prof-link"
          >
            View Profile
          </Link>
        </div>
      </li>
    ));
  }
  return (
    <ul className="prof-daily-recom-lists mt-5 mb-5">
      {renderSearchFilterData()}
    </ul>
  );
}
