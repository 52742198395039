import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { loadStripe } from "@stripe/stripe-js";
import { API_BASE_URL } from "../../api_config";
import UserContext from "../../Authcontext";

function StripeThree() {
  const { token } = useContext(UserContext);
  const [product, setProduct] = useState({
    name: "Diamond",
    price: 18000,
    quantity: 1,
  });

  async function makePayment() {
    try {
      const stripe = await loadStripe(
        "pk_live_51MH1iySG1XUPH1iKDX6kZhGUaAYDBlt3FEAv1YCJneGFL3HUN5N9d5H33MRaI6q5gjXTnwZpZjkszgjyEwpcNtHM00SC6FvQfL"
      );
      const body = { product };
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      };

      const response = await fetch(
        `${API_BASE_URL}/api/create-checkout-session`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
          withCredentials: true,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create checkout session.");
      }

      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });


      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      alert("Payment error");
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      <Card style={{ width: "25rem" }}>
        <Card.Body>
          <Card.Title className="text-center display-4">{product.name} Plan</Card.Title>
          <div className="text-center mb-4">
            <span className="h2">&#8377;{product.price}</span>
            <span className="h6 text-muted ml-2">/6 months</span>
          </div>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h5 className="mb-2">Plan Details:</h5>
              <ul>
                <li><span className="bullet">&#8226;</span>Get 240 contacts and details</li>
                <li><span className="bullet">&#8226;</span>enjoy unlimited horoscope checks</li>
                <li><span className="bullet">&#8226;</span>Exclusive dedicated senior customer service executive to assist you for 6 matches</li>
                <li className="text-success font-weight-bold">Total: &#8377;{product.price}</li>
              </ul>
            </ListGroup.Item>
          </ListGroup>
          <div className="text-center mt-4">
            <Button variant="primary" size="lg" onClick={makePayment}>
              Proceed to Payment
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default StripeThree;
