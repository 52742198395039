import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../api_config";
import { UserContext } from "../../Components/Authcontext";
import Load from "../Loading/Load";

import "./Col.css";
import axios from "axios";

function Cool() {
  const { user, setUser, token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [marital, setMarital] = useState("");
  const [tongue, setTongue] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState(user.userMob || "");
  const [alt, setAlt] = useState("");
  const [age, setAge] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setMobile(user.userMob || "");
  }, [user]);

  const calculateAge = (dob) => {
    if (dob) {
      const dobDate = new Date(dob);
      if (!isNaN(dobDate)) {
        const currentDate = new Date();
        const userAge = currentDate.getFullYear() - dobDate.getFullYear();
        setAge(userAge);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Invalid email format");
      return;
    } else {
      setEmailError("");
    }

    // Mobile number validation
    const mobilePattern = /^[0-9]{10}$/;
    if (!mobilePattern.test(mobile)) {
      setMobileError("Invalid mobile number format");
      return;
    } else {
      setMobileError("");
    }
    setLoading(true);
    const dobDate = new Date(dob);
    const currentDate = new Date();
    const userAge = currentDate.getFullYear() - dobDate.getFullYear();
    setAge(userAge);
    const response = await axios.post(
      `${API_BASE_URL}/api/basic-info`,
      {
        user,
        first,
        last,
        dob,
        gender,
        marital,
        tongue,
        email,
        mobile,
        alt,
        age,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    if (response.data.auth) {
      setUser((prevState) => ({ ...prevState, name: response.data.name }));
      setLoading(false);
      navigate("/contact-info", { replace: true });
      setFirst("");
      setLast("");
      setDob("");
      setGender("");
      setMarital("");
      setTongue("");
      setEmail("");
      setAge(null);
    }
  };

  return loading ? (
    <Load />
  ) : (
    <div className="abc">
      <div className="page-wrapper bg-red p-t-180 p-b-100">
        <div className="wrapper wrapper--w960">
          <div className="card card-2">
            <div className="card-body">
              <h2 className="title">Basic Info</h2>
              <span className="req-des">All fields are mandatory</span>
              <form method="POST" onSubmit={handleSubmit}>
                <div className="input-group">
                  <input
                    className="input--style-2"
                    type="text"
                    value={first}
                    placeholder="First Name"
                    name="name"
                    onChange={(e) => setFirst(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group">
                  <input
                    className="input--style-2"
                    type="text"
                    value={last}
                    placeholder="Last Name"
                    name="name"
                    onChange={(e) => setLast(e.target.value)}
                    required
                  />
                </div>

                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group">
                      <input
                        className="input--style-2 js-datepicker"
                        type="date"
                        value={dob}
                        placeholder="Date of Birth"
                        name="birthday"
                        required
                        onChange={(e) => {
                          setDob(e.target.value);
                          calculateAge(e.target.value);
                        }}
                      />
                      <i className="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                    </div>
                    <div className="input-group">
                      <input
                        className="input--style-2"
                        type="tel"
                        value={age}
                        placeholder="age"
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={gender}
                        name="Gender"
                        id="sex"
                        className="input--style-2 mb-4 browser-default"
                        required
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option>Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                  </div>

                  {/* <div className="row row-space"> */}
                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        id="marital"
                        value={marital}
                        className="input--style-2  browser-default"
                        onChange={(e) => setMarital(e.target.value)}
                        required
                      >
                        <option readOnly>Marital Status</option>
                        <option value="Married">Married</option>
                        <option value="UnMarried">UnMarried</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widower">Widower</option>
                        <option value="Awaiting divorce">
                          Awaiting divorce
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="input-group1">
                  <select
                    id="mother tongue"
                    value={tongue}
                    className="input--style-2  browser-default"
                    onChange={(e) => setTongue(e.target.value)}
                    required
                  >
                    <option readOnly>Mother Tongue</option>
                    <option value="Tamil">Tamil</option>
                    <option value="Telugu">Telugu</option>
                    <option value="Malayalam">Malayalam</option>
                    <option value="Kannada">Kannada</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Marathi">Marathi</option>
                    <option value="Gujarati">Gujarati</option>
                    <option value="Bengali">Bengali</option>
                    <option value="Punjabi">Punjabi</option>
                    <option value="Urdu">Urdu</option>
                    <option value="Sanskrit">Sanskrit</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="input-group">
                  <input
                    className="input--style-2"
                    type="email"
                    value={email}
                    placeholder="Email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="input-group">
                  <input
                    className="input--style-2"
                    type="text"
                    value={mobile}
                    placeholder="Mobile Number"
                    name="Mb"
                    readOnly
                  />
                </div>
                <div className="input-group">
                  <input
                    className="input--style-2"
                    type="tel"
                    value={alt}
                    placeholder="Alternative Number"
                    name="AMb"
                    onChange={(e) => {
                      const altNum = e.target.value.replace(/\D/g, "");
                      setAlt(altNum);
                    }}
                  />
                </div>
                <div className="p-t-30">
                  <div className="button mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary text-center"
                    >
                      Save & Next
                    </button>
                  </div>
                </div>
                {/* Error messages for email and mobile */}
                {emailError && <p className="error-message">{emailError}</p>}
                {mobileError && <p className="error-message">{mobileError}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cool;
