import { useState, useContext, useRef, useEffect } from "react";
import { CgProfile } from "react-icons/cg";
import { FiEdit2 } from "react-icons/fi";
import axios from "axios";
import { Link } from "react-router-dom";
import UserContext from "../Authcontext";
import { API_BASE_URL } from "../api_config";

const HomeProf = () => {
  const { user, token, fetchUserData } = useContext(UserContext);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const currentTime = new Date();
    const hours = currentTime.getHours();

    if (hours < 12) {
      setGreeting("Good Morning!");
    } else if (hours < 18) {
      setGreeting("Good Afternoon!");
    } else {
      setGreeting("Good Evening!");
    }
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      // Upload the selected image to the backend
      const formData = new FormData();
      formData.append("image", file);

      // Make the API call to upload the image to the backend
      axios
        .put(`${API_BASE_URL}/upload/update-img`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        })
        .then((res) => fetchUserData())
        .catch((error) => {
          alert("Error updating image",);
        });
    }
  };

  return (
    <div className="homeprof-cont">
      <div className="homeprof-profile-cont">
        <div className="homeprof-profile-top">
          <div className="homeprof-profile">
            <label htmlFor="image-upload" className="edit-icon">
              <div className="image-container">
                {imagePreview || user.filename ? (
                  <img
                    src={
                      imagePreview || `${API_BASE_URL}/uploads/` + user.filename
                    }
                    alt={`${user.first_name}`}
                    className="profile-image"
                  />
                ) : (
                  <CgProfile className="prof-daily-recom-img" />
                )}
                <label htmlFor="image-upload" className="edit-icon-label">
                  <FiEdit2 className="edit-icon-image" />
                </label>
              </div>
              <input
                type="file"
                id="image-upload"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageChange}
              />
            </label>
          </div>
          <div className="homeprof-username">
            <p>{greeting}</p>
            <h1>
              {user.first_name} {user.last_name}
            </h1>
          </div>
        </div>
      </div>
      <div className="homeprof-settings">
        <ul className="homeprof-setting-list">
          <li className="homeprof-setting-item">
            <Link className="mod" to="/edit-profile">
              Edit Profile
            </Link>
          </li>
          <li className="homeprof-setting-item">
            <Link className="mod" to="/edit-partner-preferences">
              Edit Preferences
            </Link>
          </li>
          <li className="homeprof-setting-item">
            <Link className="mod" to="/horoscope">
              Horoscope
            </Link>
          </li>
          <li className="homeprof-setting-item">
            <Link className="mod" to="/Mailbox">
              Mailbox
            </Link>
          </li>
        </ul>
        <ul className="homeprof-setting-list">
          <li className="homeprof-setting-item">Help & Services</li>
          <li className="homeprof-setting-item">Success Stories</li>
        </ul>
      </div>
    </div>
  );
};

export default HomeProf;
