import { useContext, useState } from "react";
import axios from "axios";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";

import { API_BASE_URL } from "../api_config";
import { useNavigate } from "react-router-dom";

import ForgotPassContext from "./ForgotPassContext";

import verImg from "../../assets/verification-img.png";
import comLogo from "../../assets/logo.png";

import "./forgotpass.css";

export default function Femail() {
  const { setUser } = useContext(ForgotPassContext);
  const [femail, setFemail] = useState("");
  const navigate = useNavigate();

  const sendMob = async () => {
    try {
      await axios
        .post(
          `${API_BASE_URL}/api/email/send-email`,
          { email: femail },
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setUser(res.data.user.user_id, res.data.user.email);
          navigate("/login/identify/otp");
          setFemail("")
        })
        .catch((err) => alert("Something error occured"));
    } catch (error) {
      alert("Something error occured")
    }
  };

  return (
    <div className="ver-otp-cont">
      <img src={verImg} alt="verification" />
      <Flex className="otp-form-cont" align="center" justify="center">
        <Stack
          className="form-otp-wrapper"
          spacing={8}
          mx="auto"
          maxW="lg"
          py={12}
          px={6}
        >
          <img src={comLogo} alt="Tamil Vokkaliga matrimony" />
          <Stack className="form-otp-container" align="center">
            <Heading as="h1" className="otp-heading" fontSize="xl">
              Verification
            </Heading>
            <Box
              rounded="lg"
              bg={useColorModeValue("white", "gray.700")}
              boxShadow="lg"
              p={8}
            >
              <Stack spacing={4}>
                <FormControl id="mob">
                  <FormLabel>Enter Your EMail ID</FormLabel>
                  <Input
                    className="otp-input"
                    type="email"
                    value={femail}
                    placeholder="Your Email ID"
                    onChange={(e) => setFemail(e.target.value)}
                  />
                </FormControl>

                <Stack spacing={10}>
                  <Button
                    className="otp-submit-btn"
                    bg="blue.400"
                    color="white"
                    _hover={{
                      bg: "blue.500",
                    }}
                    onClick={() => sendMob()}
                  >
                    Get OTP
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Flex>
    </div>
  );
}
