import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import { UserProvider } from "./Components/Authcontext";
import { AuthProvider } from "./Components/Admin/AdminAuthContext";
import { PassProvider } from "./Components/ForgotPass/ForgotPassContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserProvider>
      <PassProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </PassProvider>
    </UserProvider>
  </BrowserRouter>
);
