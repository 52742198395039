import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { API_BASE_URL } from "../api_config";
import UserContext from "../Authcontext";
import {
  fetchCasteOptions,
  fetchEducationData,
  fetchMultiKulam,
  fetchMultiRaasi,
  fetchMultiStar,
  fetchOccupations,
} from "../apis";
import "./Col.css";
import { useEffect } from "react";
import Load from "../Loading/Load";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "auto",
    minHeight: "40px",
    borderRadius: "10px",
    boxShadow: "none",
    borderColor: "#e4e4e4",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "9999",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
    overflowY: "scroll",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e4e4e4",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#333",
    ":hover": {
      backgroundColor: "#e4e4e4",
      color: "#333",
    },
  }),
};

function PartnerPreferenceDetails() {
  const { user, token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [pMinAge, setPMinAge] = useState(20);
  const [pMaxAge, setPMaxAge] = useState(20);
  const [pmarital, setPMarital] = useState("");
  const [mtongue, setMtongue] = useState("");
  const [peducation, setPeducation] = useState([]);
  const [employ, setemploy] = useState("");
  const [occupa, setOccupa] = useState("");
  const [monincome, setMonincome] = useState("");
  const [preligion, setPreligion] = useState("");
  const [pcaste, setPcaste] = useState("");
  const [pkulam, setPkulam] = useState([]);
  const [pstar, setPStar] = useState([]);
  const [praasi, setPRaasi] = useState([]);
  const [pheight, setPheight] = useState("");
  const [pweight, setPweight] = useState("");
  const [pbodytype, setPbodytype] = useState("");
  const [pcomplexion, setPcomplexion] = useState("");
  const [pphysical, setPphysical] = useState("");
  const [peducationData, setPEducationData] = useState([]);
  const [poccupationData, setPOccupationData] = useState([]);
  const [pcasteOptions, setPCasteOptions] = useState([]);
  const [pRaasiOptions, setPRaasiOptions] = useState([]);
  const [pStarOptions, setPStarOptions] = useState([]);
  const [pKulamOptions, setPKulamOptions] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    const eduData = await fetchEducationData();
    const occData = await fetchOccupations();
    const casteData = await fetchCasteOptions();
    const raasiData = await fetchMultiRaasi();
    const starData = await fetchMultiStar();
    const kulamData = await fetchMultiKulam();

    setPEducationData(eduData);
    setPOccupationData(occData);
    setPCasteOptions(casteData);
    setPRaasiOptions(raasiData);
    setPStarOptions(starData);
    setPKulamOptions(kulamData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      pmarital === "" ||
      mtongue === "" ||
      employ === "" ||
      occupa === "" ||
      monincome === "" ||
      preligion === "" ||
      pcaste === "" ||
      pheight === "" ||
      pweight === "" ||
      pbodytype === "" ||
      pcomplexion === "" ||
      pphysical === ""
    ) {
      setError("Please select all fields");
      return;
    } else {
      setError("");
    }
    setLoading(true);
    const selectedPEducations = peducation.map((edu) => edu.value);
    const selectedPKulam = pkulam.map((klm) => klm.value);
    const selectedPStar = pstar.map((str) => str.value);
    const selectedPRaasi = praasi.map((rasi) => rasi.value);
    const response = await axios.post(
      `${API_BASE_URL}/api/PartnerPreferenceDetails`,
      {
        user,
        pMinAge,
        pMaxAge,
        pmarital,
        peducation: selectedPEducations,
        employ,
        mtongue,
        occupa,
        monincome,
        preligion,
        pcaste,
        pkulam: selectedPKulam,
        pstar: selectedPStar,
        praasi: selectedPRaasi,
        pheight,
        pweight,
        pbodytype,
        pcomplexion,
        pphysical,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    if (response.data.auth) {
      setLoading(false);
      navigate("/image", { replace: true });
      setPMarital("");
      setPeducation("");
      setemploy("");
      setMtongue("");
      setOccupa("");
      setMonincome("");
      setPreligion("");
      setPcaste("");
      setPkulam("");
      setPStar("");
      setPRaasi("");
      setPheight("");
      setPweight("");
      setPbodytype("");
      setPcomplexion("");
      setPphysical("");
    }
  };

  return loading ? (
    <Load />
  ) : (
    <div className="abc">
      <div className="page-wrapper bg-red p-t-180 p-b-100">
        <div className="wrapper wrapper--w960">
          <div className="card card-2">
            <div className="card-body">
              <h2 className="title">Partner Preference Details</h2>
              <span className="req-des">All fields are mandatory</span>
              <form method="POST" onSubmit={handleSubmit}>
                <div className="input-group1">
                  <label>Min Age</label>
                  <select
                    id="minAge"
                    value={pMinAge}
                    placeholder="Select Min Age"
                    onChange={(e) => {
                      const selectedValue = parseInt(e.target.value);
                      setPMinAge(selectedValue);
                      setPMaxAge(selectedValue); // Update maxAge to match minAge
                    }}
                    required
                  >
                    {[...Array(61)].map((_, index) => (
                      <option key={index + 20} value={index + 20}>
                        {index + 20}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-group1">
                  <label>Max Age</label>
                  <select
                    id="maxAge"
                    value={pMaxAge}
                    placeholder="Select Max Age"
                    onChange={(e) => setPMaxAge(e.target.value)}
                    required
                  >
                    {[...Array(81 - parseInt(pMinAge, 10))].map((_, index) => {
                      const maxValue = parseInt(pMinAge, 10) + index;
                      return (
                        <option key={maxValue} value={maxValue}>
                          {maxValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={pmarital}
                        id="marital"
                        className="input--style-2  browser-default"
                        onChange={(e) => setPMarital(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select Marital Status
                        </option>
                        <option value="Married">Married</option>
                        <option value="UnMarried">UnMarried</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widow">Widow</option>
                        <option value="Awaiting divorce">
                          Awaiting divorce
                        </option>
                        <option value="Any">Any</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={mtongue}
                        id="tongue"
                        className="input--style-2  browser-default "
                        onChange={(e) => setMtongue(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select Mother Tongue
                        </option>
                        <option value="Tamil">Tamil</option>
                        <option value="Telugu">Telugu</option>
                        <option value="Malayalam">Malayalam</option>
                        <option value="Kannada">Kannada</option>
                        <option value="Hindi">Hindi</option>
                        <option value="Marathi">Marathi</option>
                        <option value="Gujarati">Gujarati</option>
                        <option value="Bengali">Bengali</option>
                        <option value="Punjabi">Punjabi</option>
                        <option value="Urdu">Urdu</option>
                        <option value="Sanskrit">Sanskrit</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="input-group1">
                  <Select
                    options={peducationData}
                    value={peducation}
                    onChange={(selectedOptions) =>
                      setPeducation(selectedOptions)
                    }
                    isMulti
                    placeholder="Select Education"
                    styles={customStyles}
                    required
                  />
                </div>

                <div className="input-group1">
                  <select
                    value={employ}
                    id="employ"
                    className="input--style-2  browser-default"
                    onChange={(e) => setemploy(e.target.value)}
                    required
                  >
                    <option value="" disabled="disabled">
                      Employment Type
                    </option>
                    <option value="Employed">Employed</option>
                    <option value="Self-employed">Self-employed</option>
                    <option value="Out of work">Out of work</option>
                    <option value="Homemaker">Homemaker</option>
                    <option value="Student">Student</option>
                    <option value="Retired">Retired</option>
                    <option value="Unable to work">Unable to work</option>
                    <option value="Any">Any</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={occupa}
                    id="occupation"
                    className="input--style-2  browser-default"
                    onChange={(e) => setOccupa(e.target.value)}
                    required
                  >
                    <option value="" disabled="disabled">
                      Occupation
                    </option>
                    {poccupationData.map((occ) => (
                      <option key={occ.id} value={occ.occupation}>
                        {occ.occupation}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    id="annualincome"
                    value={monincome}
                    name="income"
                    className="input--style-2  browser-default"
                    onChange={(e) => setMonincome(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select Annual Income
                    </option>
                    <option value="500000">Less than ₹5,00,000</option>
                    <option value="1000000">₹5,00,000 - ₹10,00,000</option>
                    <option value="1500000">₹10,00,000 - ₹15,00,000</option>
                    <option value="2000000">₹15,00,000 - ₹20,00,000</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={preligion}
                    id="religion"
                    className="input--style-2  browser-default "
                    onChange={(e) => setPreligion(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Religion
                    </option>
                    <option value="African Traditional &amp; Diasporic">
                      African Traditional &amp; Diasporic
                    </option>
                    <option value="Agnostic">Agnostic</option>
                    <option value="Atheist">Atheist</option>
                    <option value="Baha'i">Baha'i</option>
                    <option value="Buddhism">Buddhism</option>
                    <option value="Cao Dai">Cao Dai</option>
                    <option value="Chinese traditional religion">
                      Chinese traditional religion
                    </option>
                    <option value="Christianity">Christianity</option>
                    <option value="Hindu">Hinduism</option>
                    <option value="Islam">Islam</option>
                    <option value="Jainism">Jainism</option>
                    <option value="Juche">Juche</option>
                    <option value="Judaism">Judaism</option>
                    <option value="Neo-Paganism">Neo-Paganism</option>
                    <option value="Nonreligious">Nonreligious</option>
                    <option value="Rastafarianism">Rastafarianism</option>
                    <option value="Secular">Secular</option>
                    <option value="Shinto">Shinto</option>
                    <option value="Sikhism">Sikhism</option>
                    <option value="Spiritism">Spiritism</option>
                    <option value="Tenrikyo">Tenrikyo</option>
                    <option value="Unitarian-Universalism">
                      Unitarian-Universalism
                    </option>
                    <option value="Zoroastrianism">Zoroastrianism</option>
                    <option value="primal-indigenous">primal-indigenous</option>
                    <option value="Any">Any</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={pcaste}
                    id="caste"
                    className="input--style-2  browser-default "
                    onChange={(e) => setPcaste(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Caste
                    </option>
                    {pcasteOptions.map((caste) => (
                      <option key={caste.id} value={caste.caste}>
                        {caste.caste}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <Select
                    options={pKulamOptions}
                    value={pkulam}
                    onChange={(selectedOptions) => setPkulam(selectedOptions)}
                    isMulti
                    placeholder="Select Kulam"
                    styles={customStyles}
                  />
                </div>

                <div className="input-group1">
                  <Select
                    options={pStarOptions}
                    value={pstar}
                    onChange={(selectedOptions) => setPStar(selectedOptions)}
                    isMulti
                    placeholder="Select Star"
                    styles={customStyles}
                    required
                  />
                </div>

                <div className="input-group1">
                  <Select
                    options={pRaasiOptions}
                    value={praasi}
                    onChange={(selectedOptions) => setPRaasi(selectedOptions)}
                    isMulti
                    placeholder="Select Raasi"
                    styles={customStyles}
                    required
                  />
                </div>

                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={pheight}
                        id="height"
                        className="input--style-2  browser-default"
                        onChange={(e) => setPheight(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Min-Max height
                        </option>
                        <option value="150">120-150 cm</option>
                        <option value="170">150-170 cm</option>
                        <option value="190">170-190 cm</option>
                        <option value="210">190-210 cm</option>
                        <option value="Any">Any</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={pweight}
                        id="weight"
                        className="input--style-2  browser-default "
                        onChange={(e) => setPweight(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Min-Max weight
                        </option>
                        <option value="60">40-60 kg</option>
                        <option value="80">60-80 kg</option>
                        <option value="100">80-100 kg</option>
                        <option value="150">100-150 kg</option>
                        <option value="Any">Any</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={pbodytype}
                        id="bodytype"
                        className="input--style-2  browser-default "
                        onChange={(e) => setPbodytype(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Body Type
                        </option>
                        <option value="Slim">Slim</option>
                        <option value="Average">Average</option>
                        <option value="Athletic">Athletic</option>
                        <option value="Heavy">Heavy</option>
                        <option value="Any">Any</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={pcomplexion}
                        id="complexion"
                        className="input--style-2  browser-default "
                        onChange={(e) => setPcomplexion(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Complexion
                        </option>
                        <option value="Very Fair">Very Fair</option>
                        <option value="Fair">Fair</option>
                        <option value="Wheatish">Wheatish</option>
                        <option value="Wheatish Brown">Wheatish Brown</option>
                        <option value="Dark">Dark</option>
                        <option value="Any">Any</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="input-group1">
                  <select
                    value={pphysical}
                    id="physical"
                    className="input--style-2  browser-default "
                    onChange={(e) => setPphysical(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Physical Status
                    </option>
                    <option value="Normal">Normal</option>
                    <option value="Physically Challenged">
                      Physically Challenged
                    </option>
                    <option value="Any">Any</option>
                  </select>
                </div>
                {error && <p className="error-message">{error}</p>}
                <div className="p-t-30">
                  <div className="button mt-1">
                    <button
                      type="submit"
                      className="btn btn-primary text-center"
                    >
                      Save & Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerPreferenceDetails;
