import React, { useContext, useEffect } from "react";
import logo from "../assets/logo.png";
import UserContext from "./Authcontext";
import Footer from "./Foote/Footer";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function UserVerification() {
  const { user, fetchUserData } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  });

  useEffect(() => {
    if (user.verify === 1) {
      navigate("/", { replace: true });
    } else {
      navigate("/user-under-verification", {replace: true});
    }
  },[user.verify, navigate]);

  const handleLogout = () => {
    Cookies.remove("m_host-token");
    navigate("/signup", { replace: true });
  };

  return (
    <>
      <div className="home-cont verify">
        <div className="verify-cont">
          <img src={logo} alt="Vokkaliga Matrimony" />
          <h1 className="verify-cont-heading">
            Hi, <span>{user.first_name}</span>
          </h1>
          <p className="verify-cont-wel">Thank you for choosing us</p>
          <p className="verify-cont-ver">
            Your profile will get verified within 24Hrs
          </p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UserVerification;
