import React, { createContext, useState } from "react";

export const ForgotPassContext = createContext();

export const PassProvider = ({ children }) => {
  const [userData, setUserData] = useState({ userId: null, email: null });

  const setUser = (userId, email) => {
    setUserData({ userId, email });
  };

  return (
    <ForgotPassContext.Provider value={{ userData, setUser }}>
      {children}
    </ForgotPassContext.Provider>
  );
};

export default ForgotPassContext;