import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { API_BASE_URL } from "../api_config";
import UserContext from "../Authcontext";
import { fetchEducationData, fetchOccupations } from "../apis";
import Load from "../Loading/Load";
import "./Col.css";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "auto",
    minHeight: "40px",
    borderRadius: "10px",
    boxShadow: "none",
    borderColor: "#e4e4e4",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "9999",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
    overflowY: "scroll",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e4e4e4",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#333",
    ":hover": {
      backgroundColor: "#e4e4e4",
      color: "#333",
    },
  }),
};

function PersonalDetails() {
  const { user, token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [education, setEducation] = useState([]);
  const [emptype, setEmptype] = useState("");
  const [occupation, setOccupation] = useState("");
  const [companyName, setCompanyName] = useState("")
  const [income, setIncome] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bodytype, setBodytype] = useState("");
  const [complexion, setComplexion] = useState("");
  const [physical, setPhysical] = useState("");
  const [educationData, setEducationData] = useState([]);
  const [occupationData, setOccupationData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    const eduData = await fetchEducationData();
    const occData = await fetchOccupations();

    setEducationData(eduData);
    setOccupationData(occData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const selectedEducations = education.map((edu) => edu.value);
    const response = await axios.post(
      `${API_BASE_URL}/api/PersonalDetails`,
      {
        user,
        education: selectedEducations,
        emptype,
        occupation,
        income,
        companyName,
        height,
        weight,
        bodytype,
        complexion,
        physical,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    if (response.data.auth) {
      setLoading(false);
      navigate("/family-info", { replace: true });
      setEducation("");
      setEmptype("");
      setOccupation("");
      setIncome("");
      setHeight("");
      setWeight("");
      setBodytype("");
      setComplexion("");
      setPhysical("");
    }
  };

  return loading ? (
    <Load />
  ) : (
    <div className="abc">
      <div className="page-wrapper bg-red p-t-180 p-b-100 ">
        <div className="wrapper wrapper--w960">
          <div className="card card-2">
            <div className="card-body">
              <h2 className="title">Personal Details</h2>
              <span className="req-des">All fields are mandatory</span>
              <form onSubmit={handleSubmit}>
                <div className="input-group1">
                  <Select
                    options={educationData}
                    value={education}
                    onChange={(selectedOptions) => {
                      setEducation(selectedOptions);
                    }}
                    isMulti
                    placeholder="Select Education"
                    styles={customStyles}
                    required
                  />
                </div>
                <div className="row row-space">
                  <div className="input-group1">
                    <select
                      value={emptype}
                      className="input--style-2"
                      placeholder="Employment Type"
                      name="name"
                      onChange={(e) => setEmptype(e.target.value)}
                      required
                    >
                      <option value="">Employment Type</option>
                      <option value="Employed">Employed</option>
                      <option value="Self-employed">Self-employed</option>
                      <option value="Out of work">Out of work</option>
                      <option value="Homemaker">Homemaker</option>
                      <option value="Student">Student</option>
                      <option value="Retired">Retired</option>
                      <option value="Unable to work">Unable to work</option>
                    </select>
                  </div>

                  <div className="input-group1">
                    <select
                      value={occupation}
                      className="input--style-2"
                      placeholder="Occupation"
                      name="name"
                      onChange={(e) => setOccupation(e.target.value)}
                      required
                    >
                      <option value="">Occupation</option>
                      {occupationData.map((occ) => (
                        <option key={occ.id} value={occ.occupation}>
                          {occ.occupation}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="input-group">
                  <input
                    value={companyName}
                    className="input--style-2"
                    type="text"
                    placeholder="Company name and location"
                    name="name"
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group">
                  <input
                    value={income}
                    className="input--style-2"
                    type="text"
                    placeholder="Annual Income"
                    name="name"
                    onChange={(e) => setIncome(e.target.value)}
                    required
                  />
                </div>
                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={height}
                        id="height"
                        className="input--style-2  browser-default "
                        onChange={(e) => setHeight(e.target.value)}
                        required
                      >
                        <option value="">Height</option>
                        <option value="150">4 ft 11 in (150 cm)</option>
                        <option value="155">5 ft 1 in (155 cm)</option>
                        <option value="160">5 ft 3 in (160 cm)</option>
                        <option value="165">5 ft 5 in (165 cm)</option>
                        <option value="170">5 ft 7 in (170 cm)</option>
                        <option value="175">5 ft 9 in (175 cm)</option>
                        <option value="180">5 ft 11 in (180 cm)</option>
                        <option value="185">6 ft 1 in (185 cm)</option>
                        <option value="190">6 ft 3 in (190 cm)</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={weight}
                        id="weight"
                        className="input--style-2  browser-default "
                        onChange={(e) => setWeight(e.target.value)}
                        required
                      >
                        <option value="">Weight</option>
                        <option value="50">50 kg</option>
                        <option value="55">55 kg</option>
                        <option value="60">60 kg</option>
                        <option value="65">65 kg</option>
                        <option value="70">70 kg</option>
                        <option value="75">75 kg</option>
                        <option value="80">80 kg</option>
                        <option value="85">85 kg</option>
                        <option value="90">90 kg</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row row-space">
                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={bodytype}
                        id="bodytype"
                        className="input--style-2  browser-default  "
                        onChange={(e) => setBodytype(e.target.value)}
                        required
                      >
                        <option value="">Body Type</option>
                        <option value="Slim">Slim</option>
                        <option value="Average">Average</option>
                        <option value="Athletic">Athletic</option>
                        <option value="Heavy">Heavy</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="input-group1">
                      <select
                        value={complexion}
                        id="complexion"
                        className="input--style-2  browser-default  "
                        onChange={(e) => setComplexion(e.target.value)}
                        required
                      >
                        <option value="">Complexion</option>
                        <option value="Very Fair">Very Fair</option>
                        <option value="Fair">Fair</option>
                        <option value="Wheatish">Wheatish</option>
                        <option value="Wheatish Brown">Wheatish Brown</option>
                        <option value="Dark">Dark</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="input-group1">
                  <select
                    value={physical}
                    id="physical"
                    className="input--style-2  browser-default "
                    onChange={(e) => setPhysical(e.target.value)}
                    required
                  >
                    <option value="">Physical Status</option>
                    <option value="Normal">Normal</option>
                    <option value="Physically Challenged">
                      Physically Challenged
                    </option>
                  </select>
                </div>

                <div className="p-t-30">
                  <div className="button mt-1">
                    <button
                      type="submit"
                      className="btn btn-primary text-center"
                    >
                      Save & Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
