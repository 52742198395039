import React from "react";
import "./SS.css";
import { FaHeart } from "react-icons/fa";
import ss1 from '../../assets/kerala-palakkad-wedding-photography-glareart-photography-15_15_135446_v1-fotor-20230703123322-fotor-20230703123446.jpg'
import ss2 from '../../assets/30105873808_0edef44329_o-1-1-e1533986588766-fotor-20230703124617.jpg';
import ss3 from '../../assets/37715-tamil-wedding-songs-light-bucket-productions-lead-fotor-20230703125145.jpg';
function SS() {
  return (
    <section className="bg-light mt-5" id="tourist">
      {/* <div className="fluid-container">
        <div className="row text-center"> */}
      <div className="col-sm-12 col-md-12 mb-4">
        <div className="line">
          <h3 className="text-center text-secondary">
            <hr className="pink-line" />
            Successful
            <FaHeart className="heart-icn" />
            Stories
          </h3>
          <hr className="pink-line1" />
        </div>
      </div>
      <div className="client-rev-list">
        <div className="testimonial mb-5">
          <div className="avatar">
            <img
              alt="ss1"
              src={ss1}
              className="rounded-circle z-depth-1 img-fluid"
            />
          </div>
          {/* <h4 className="font-weight-bold dark-grey-text mt-4">soppanasundari-goundamani</h4> */}

          <p className="font-weight-normal ">
            Nithya and Aravind's journey began on Tamil Vokkaliga Matrimony,
            where they both sought a partner who could appreciate the balance
            between tradition and modernity. They found that connection in each
            other. Their wedding was a beautiful fusion of Vokkaliga traditions
            and contemporary values, illustrating the harmony they've discovered
            in their lives together.
          </p>
        </div>
        {/* </div> */}

        {/* <div class="col-md-4"> */}
        <div className="testimonial mb-5">
          <div className="avatar">
            <img
              alt="ss2"
              src={ss2}
              className="rounded-circle z-depth-1 img-fluid"
            />
          </div>
          {/* <h4 className="font-weight-bold dark-grey-text mt-4">saroja-premji</h4> */}

          <p className="font-weight-normal text-wrap">
            Kaviya and Manoj's story is a testament to the power of shared
            values. They met on our platform, and their conversations were
            filled with discussions about their love for their culture, family,
            and community. Their bond grew stronger as they discovered their
            compatibility in these areas, ultimately leading to a joyous union.
          </p>
        </div>
        {/* </div> */}
        {/* <div class="col-md-4"> */}
        <div className="testimonial mb-5">
          <div className="avatar">
            <img
              alt="ss3"
              src={ss3}
              className="rounded-circle z-depth-1 img-fluid"
            />
          </div>
          {/* <h4 className="font-weight-bold dark-grey-text mt-4">sornaka-bijili</h4> */}

          <p className="font-weight-normal">
            Deepa and Karthik had never met before they connected on Tamil
            Vokkaliga Matrimony. As they conversed, they realized that they
            shared not only a cultural background but also dreams and
            aspirations. They took a leap of faith, met in person, and their
            connection deepened. Today, they are happily married, proving that
            sometimes, your soulmate is just a click away.
          </p>
        </div>
      </div>
      {/* </div> */}
      {/* </div>
      </div> */}
    </section>
  );
}

export default SS;
