import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../api_config";
import UserContext from "../Authcontext";
import "./Col.css";
import {
  fetchCasteOptions,
  fetchKulamOptions,
  fetchRaasiOptions,
  fetchStarOptions,
} from "../apis";
import Load from "../Loading/Load";

function ReligiousInformation() {
  const { user, token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [religion, setReligion] = useState("");
  const [caste, setCaste] = useState("");
  const [Kulam, setKulam] = useState("");
  const [star, setStar] = useState("");
  const [raasi, setRaasi] = useState("");
  const [chevvai, setChevvai] = useState("");
  const [naga, setNaga] = useState("");
  const [raasiOptions, setRaasiOptions] = useState([]);
  const [starOptions, setStarOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [kulamOptions, setKulamOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchReligiousOptions();
  }, []);

  const fetchReligiousOptions = async () => {
    const raasiData = await fetchRaasiOptions();
    const starData = await fetchStarOptions();
    const casteData = await fetchCasteOptions();
    const kulamData = await fetchKulamOptions();

    setRaasiOptions(raasiData);
    setStarOptions(starData);
    setCasteOptions(casteData);
    setKulamOptions(kulamData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await axios.post(
      `${API_BASE_URL}/api/ReligiousInformation`,
      {
        user,
        religion,
        caste,
        Kulam,
        star,
        raasi,
        chevvai,
        naga,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    if (response.data.auth) {
      setLoading(false);
      navigate("/habit-info", { replace: true });
      setReligion("");
      setCaste("");
      setKulam("");
      setStar("");
      setRaasi("");
      setChevvai("");
      setNaga("");
    }
  };

  return loading ? (
    <Load />
  ) : (
    <div className="abc">
      <div className="page-wrapper bg-red p-t-180 p-b-100">
        <div className="wrapper wrapper--w960">
          <div className="card card-2">
            <div className="card-body">
              <h2 className="title">Religious Information</h2>
              <span className="req-des">All fields are mandatory</span>
              <form method="POST" onSubmit={handleSubmit}>
                <div className="input-group1">
                  <select
                    value={religion}
                    id="religion"
                    className="input--style-2  browser-default "
                    onChange={(e) => setReligion(e.target.value)}
                    required
                  >
                    <option value="" disabled="disabled">
                      Religion
                    </option>
                    <option value="African Traditional &amp; Diasporic">
                      African Traditional &amp; Diasporic
                    </option>
                    <option value="Agnostic">Agnostic</option>
                    <option value="Atheist">Atheist</option>
                    <option value="Baha'i">Baha'i</option>
                    <option value="Buddhism">Buddhism</option>
                    <option value="Cao Dai">Cao Dai</option>
                    <option value="Chinese traditional religion">
                      Chinese traditional religion
                    </option>
                    <option value="Christianity">Christianity</option>
                    <option value="Hinduism">Hinduism</option>
                    <option value="Islam">Islam</option>
                    <option value="Jainism">Jainism</option>
                    <option value="Juche">Juche</option>
                    <option value="Judaism">Judaism</option>
                    <option value="Neo-Paganism">Neo-Paganism</option>
                    <option value="Nonreligious">Nonreligious</option>
                    <option value="Rastafarianism">Rastafarianism</option>
                    <option value="Secular">Secular</option>
                    <option value="Shinto">Shinto</option>
                    <option value="Sikhism">Sikhism</option>
                    <option value="Spiritism">Spiritism</option>
                    <option value="Tenrikyo">Tenrikyo</option>
                    <option value="Unitarian-Universalism">
                      Unitarian-Universalism
                    </option>
                    <option value="Zoroastrianism">Zoroastrianism</option>
                    <option value="primal-indigenous">primal-indigenous</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={caste}
                    id="caste"
                    className="input--style-2  browser-default "
                    onChange={(e) => setCaste(e.target.value)}
                    required
                  >
                    <option readOnly>Caste</option>
                    {casteOptions.map((caste) => (
                      <option key={caste.id} value={caste.caste}>
                        {caste.caste}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={Kulam}
                    id="kulam"
                    className="input--style-2  browser-default"
                    onChange={(e) => setKulam(e.target.value)}
                  >
                    <option readOnly>Kulam</option>
                    {kulamOptions.map((kulam) => (
                      <option key={kulam.id} value={kulam.kulam}>
                        {kulam.kulam}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={star}
                    id="star"
                    className="input--style-2  browser-default "
                    onChange={(e) => setStar(e.target.value)}
                    required
                  >
                    <option readOnly>Star</option>
                    {starOptions.map((star) => (
                      <option key={star.id} value={star.natchaname}>
                        {star.natchaname}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={raasi}
                    id="raai"
                    className="input--style-2  browser-default "
                    onChange={(e) => setRaasi(e.target.value)}
                    required
                  >
                    <option readOnly>Raasi</option>
                    {raasiOptions.map((raasi) => (
                      <option key={raasi.id} value={raasi.rasiname}>
                        {raasi.rasiname}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={chevvai}
                    id="chevvai"
                    className="input--style-2  browser-default "
                    onChange={(e) => setChevvai(e.target.value)}
                    required
                  >
                    <option readOnly>Chevvai Dosham</option>
                    <option value="No">No</option>
                    <option value="chevvai">Yes</option>
                    <option value="Don't Know">Don't Know</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={naga}
                    id="naga"
                    className="input--style-2  browser-default "
                    onChange={(e) => setNaga(e.target.value)}
                    required
                  >
                    <option readOnly>Naga Dosham</option>
                    <option value="No">No</option>
                    <option value="naga">Yes</option>
                    <option value="Don't Know">Don't Know</option>
                  </select>
                </div>
                <div className="p-t-30">
                  <div className="button mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary text-center"
                    >
                      Save & Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReligiousInformation;
