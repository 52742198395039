import StripeOne from "../StripePay/Stripe1";
import StripeTwo from "../StripePay/Stripe2";
import StripeThree from "../StripePay/Stripe3";

import "./payment.css";

function StripePayment() {

  return (
    <>
      <div className="pay">
        <StripeOne />
        <StripeTwo />
        <StripeThree />
      </div>
      <div className="payment-footer">
        <div className="wrapper-max">
          <center>
            <span className="pay-footer-card-visa"></span>
            <span className="pay-footer-card-mastercard"></span>
            <span className="pay-footer-card-dss"></span>
            <span className="pay-footer-card-trustwave"></span>
            <span className="pay-footer-card-safekey"></span>
            <span className="pay-footer-card-verisign"></span>
            <span className="pay-footer-card-masterpass"></span>
            <br clear="all" />
          </center>
        </div>
      </div>
    </>
  );
}

export default StripePayment;
