import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AiTwotoneHeart, AiOutlineHeart } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { UserContext } from "../Authcontext";
import { API_BASE_URL } from "../api_config";

const HomeTotalMatch = () => {
  const { user, token, likedProfiles, setLikedProfiles, fetchUserData } =
    useContext(UserContext);
  const [profiles, setProfiles] = useState([]);

  const totalMatchPath = useLocation();

  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/api/total-matches`,
        {
          params: {
            id: user.user_id,
            gender: user.gender,
            membership: user.pay_membership,
            min_age: user.pmin_age,
            max_age: user.pmax_age,
            maritalStatus: user.pmarital_status,
            language: user.pmother_tongue,
            edu: user.phighest_education,
            empType: user.pemployment_type,
            occu: user.poccupation,
            income: user.pannual_income,
            religion: user.preligion,
            caste: user.pcaste,
            kulam: user.pkulam,
            star: user.pstar,
            raasi: user.praasi,
            height: user.pheight,
            weight: user.pweight,
            bodyType: user.pbody_type,
            complexion: user.pcomplexion,
            physicalStatus: user.pphysical_status,
          },
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (Array.isArray(res.data)) {
          // console.log("total-matches count: " + res.data.length);
          // console.log("total-matches data: " + JSON.stringify(res.data));
          setProfiles(res.data);
        }
      })
      .catch((error) => {});
  }, [user]);

  const isProfileLiked = (userId) => {
    return likedProfiles !== undefined && likedProfiles.includes(userId);
  };

  const handleLikeDislike = (userId) => {
    if (isProfileLiked(userId)) {
      // Dislike the profile
      const updatedLikedProfiles = likedProfiles.filter((id) => id !== userId);
      setLikedProfiles(updatedLikedProfiles);
      axios
        .put(
          `${API_BASE_URL}/api/dislike-profile/${userId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          fetchUserData();
        })
        .catch((error) => {
          // Handle error if needed
        });
    } else {
      // Like the profile
      const updatedLikedProfiles = [...likedProfiles, userId];
      setLikedProfiles(updatedLikedProfiles);
      axios
        .put(
          `${API_BASE_URL}/api/update-like-profiles`,
          { likedProfiles: updatedLikedProfiles },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          fetchUserData();
        })
        .catch((error) => {
          // Handle error if needed
        });
    }
  };

  const renderTotalMatchProfiles = () => {
    return profiles.slice(0, 5).map((prof, i) => (
      <li className="profile-total-matches-item" key={i}>
        <div className="profile-total-match-blur">
          <div className="prof-total-match-cont">
            {prof.filename === null ? (
              <CgProfile className="prof-total-matches-img" />
            ) : (
              <img
                src={`${API_BASE_URL}/uploads/` + prof.filename}
                alt={`${prof.first_name}`}
                className="prof-total-matches-img"
              />
            )}
            <div className="prof-total-match-details">
              <h3>
                {prof.first_name} {prof.last_name}
              </h3>
              <p>age: {prof.age}</p>
              <p>education: {prof.education}</p>
              <p>occupation: {prof.occupation}</p>
              <p>
                {prof.city_name}, {prof.state_name}, {prof.country_name}
              </p>
            </div>
          </div>
        </div>
        <div className="match-intrst-cont-btns">
          {isProfileLiked(prof.user_id) ? (
            <AiTwotoneHeart
              className="match-intrst-btn"
              color="red"
              size={35}
              onClick={() => handleLikeDislike(prof.user_id)}
            />
          ) : (
            <AiOutlineHeart
              className="match-intrst-btn"
              color="white"
              size={35}
              onClick={() => handleLikeDislike(prof.user_id)}
            />
          )}
          <Link
            to={"/view-profile"}
            state={{ userId: prof.user_id }}
            className="total-match-view-prof-link"
          >
            View Profile
          </Link>
        </div>
      </li>
    ));
  };

  return (
    <div className="prof-daily-recom-container">
      <div className="prof-total-matches-heading-cont">
        <div className="prof-total-matches-heading-content">
          <div className="prof-total-matches-heading">
            <h3 className="prof-daily-recom-heading">Total Matches</h3>
            {totalMatchPath.pathname === "/" && (
              <Link to="/matches/total-matches">See All</Link>
            )}
          </div>
          <ul className="prof-daily-recom-lists">
            {renderTotalMatchProfiles()}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeTotalMatch;
