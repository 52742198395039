import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../api_config";

import { useContext, useState } from "react";
import UserContext from "../Authcontext";
import Load from "../Loading/Load";
import "./Col.css";

function HabitDetails() {
  const { user, token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [home, setHome] = useState("");
  const [land, setLand] = useState("");
  const [plat, setPlat] = useState("");
  const [food, setFood] = useState("");
  const [smoking, setSmoking] = useState("");
  const [drinking, setDrinking] = useState("");

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await axios.post(
      `${API_BASE_URL}/api/HabitDetails`,
      {
        user,
        home,
        land,
        plat,
        food,
        smoking,
        drinking,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    if (response.data.auth) {
      setLoading(false);
      navigate("/partner-preference-info", { replace: true });
      setHome("");
      setLand("");
      setPlat("");
      setFood("");
      setSmoking("");
      setDrinking("");
    }
  };
  return loading ? (
    <Load />
  ) : (
    <div className="abc">
      <div className="page-wrapper bg-red p-t-180 p-b-100">
        <div className="wrapper wrapper--w960">
          <div className="card card-2">
            <div className="card-body">
              <h2 className="title">Habit & Other Details</h2>
              <span className="req-des">All fields are mandatory</span>
              <form method="POST" onSubmit={handleSubmit}>
                <div className="input-group1">
                  <select
                    value={home}
                    id="home-details"
                    className="input--style-2  browser-default  "
                    onChange={(e) => setHome(e.target.value)}
                    required
                  >
                    <option readOnly>Home Details</option>
                    <option value="Own House">Own House</option>

                    <option value="Rented House">Rented House</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={land}
                    id="land"
                    className="input--style-2  browser-default  "
                    onChange={(e) => setLand(e.target.value)}
                    required
                  >
                    <option readOnly>Agriculture Land</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={plat}
                    id="plat"
                    className="input--style-2  browser-default  "
                    onChange={(e) => setPlat(e.target.value)}
                    required
                  >
                    <option readOnly>Flat</option>
                    <option value="No">No</option>

                    <option value="Yes">Yes</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={food}
                    id="food"
                    className="input--style-2  browser-default  "
                    onChange={(e) => setFood(e.target.value)}
                    required
                  >
                    <option readOnly>Food Habits</option>
                    <option value="Vegetarian">Vegetarian</option>

                    <option value="non-Vegetarian">non-Vegetarian</option>

                    <option value="Eggetarian">Eggetarian</option>
                    <option value="All">All</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={smoking}
                    id="smoking"
                    className="input--style-2  browser-default  "
                    onChange={(e) => setSmoking(e.target.value)}
                    required
                  >
                    <option readOnly>smoking Habits</option>
                    <option value="No">No</option>

                    <option value="Yes">Yes</option>

                    <option value="Occasionally">Occasionally</option>
                  </select>
                </div>

                <div className="input-group1">
                  <select
                    value={drinking}
                    id="drinking"
                    className="input--style-2  browser-default "
                    onChange={(e) => setDrinking(e.target.value)}
                    required
                  >
                    <option readOnly>Drinking Habits</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                    <option value="Occasionally">Occasionally</option>
                  </select>
                </div>

                <div className="p-t-30">
                  <div className="button mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary text-center"
                    >
                      Save & Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HabitDetails;
