import { useContext, useEffect, useState } from "react";
import UserContext from "../Authcontext";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../api_config";
import Select from "react-select";
import {
  fetchCasteOptions,
  fetchEducationData,
  fetchKulamOptions,
  fetchOccupations,
  fetchRaasiOptions,
  fetchStarOptions,
} from "../apis";
import axios from "axios";
import "./Basic.css";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: "auto",
    minHeight: "40px",
    borderRadius: "10px",
    boxShadow: "none",
    borderColor: "#e4e4e4",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "9999",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px",
    overflowY: "scroll",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e4e4e4",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#333",
    ":hover": {
      backgroundColor: "#e4e4e4",
      color: "#333",
    },
  }),
};

export default function BasicDetails1() {
  const { user, token, fetchUserData } = useContext(UserContext);
  const [profile, setProfile] = useState(user.profile || "");
  const [marital, setMarital] = useState(user.marital_status || "");
  const [first, setFirst] = useState(user.first_name || "");
  const [last, setLast] = useState(user.last_name || "");
  const [dob, setDob] = useState(formatDOB(user.dob) || "");
  const [age, setAge] = useState(user.age || "");
  const [gender, setGender] = useState(user.gender || "");
  const [tongue, setTongue] = useState(user.mother_tongue || "");
  const [mobile, setMobile] = useState(user.mobile_number || "");
  const [email, setEmail] = useState(user.email || "");
  const [alt, setAlt] = useState(user.altermobile_number || "");
  const [education, setEducation] = useState([]);
  const [companyName, setCompanyName] = useState(user.company_name || "");
  const [income, setIncome] = useState(user.income || "");
  const [emptype, setEmptype] = useState(user.employment_type || "");
  const [occupation, setOccupation] = useState(user.occupation || "");
  const [home, setHome] = useState(user.home_details || "");
  const [land, setLand] = useState(user.agri_land || "");
  const [plat, setPlat] = useState(user.plat || "");
  const [address, setAddress] = useState(user.address || "");
  const [address1, setAddress1] = useState(user.address1 || "");
  const [city, setCity] = useState(user.city || "");
  const [state, setState] = useState(user.state || "");
  const [country, setCountry] = useState(user.country || "");
  const [father, setFather] = useState(user.father_name || "");
  const [fcaste, setFcaste] = useState(user.father_caste || "");
  const [fkulam, setFkulam] = useState(user.father_kulam || "");
  const [mother, setMother] = useState(user.mother_name || "");
  const [mcaste, setMcaste] = useState(user.mother_caste || "");
  const [mkulam, setMkulam] = useState(user.mother_kulam || "");
  const [fstatus, setFstatus] = useState(user.family_status || "");
  const [ftype, setFtype] = useState(user.family_type || "");
  const [fvalues, setFvalues] = useState(user.family_values || "");
  const [msisters, setMsisters] = useState(user.married_sisters || "");
  const [mbrothers, setMbrothers] = useState(user.married_brothers || "");
  const [parentsno, setParentsno] = useState(user.parents_number || "");
  const [religion, setReligion] = useState(user.religion || "");
  const [caste, setCaste] = useState(user.caste || "");
  const [kulam, setKulam] = useState(user.kulam || "");
  const [star, setStar] = useState(user.star || "");
  const [raasi, setRaasi] = useState(user.raasi || "");
  const [food, setFood] = useState(user.food_habits || "");
  const [smoking, setSmoking] = useState(user.smoking_habits || "");
  const [drinking, setDrinking] = useState(user.drinking_habits || "");

  const [educationOptions, setEducationOptions] = useState([]);
  const [occupationOptions, setOccupationOptions] = useState([]);
  const [raasiOptions, setRaasiOptions] = useState([]);
  const [starOptions, setStarOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [kulamOptions, setKulamOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setProfile(user.profile || "");
    setMarital(user.marital_status || "");
    setFirst(user.first_name || "");
    setLast(user.last_name || "");
    setDob(formatDOB(user.dob) || "");
    setAge(user.age || "");
    setGender(user.gender || "");
    setTongue(user.mother_tongue || "");
    setMobile(user.mobile_number || "");
    setEmail(user.email || "");
    setAlt(user.altermobile_number || "");
    setCompanyName(user.company_name || "");
    setIncome(user.income || "");
    setEmptype(user.employment_type || "")
    setOccupation(user.occupation || "")
    setHome(user.home_details || "");
    setLand(user.agri_land || "");
    setPlat(user.plat || "");
    setAddress(user.address || "");
    setAddress1(user.address1 || "");
    setCity(user.city || "");
    setState(user.state || "");
    setCountry(user.country || "");
    setFather(user.father_name || "");
    setFcaste(user.father_caste || "");
    setFkulam(user.father_kulam || "");
    setMother(user.mother_name || "");
    setMcaste(user.mother_caste || "");
    setMkulam(user.mother_kulam || "");
    setFstatus(user.family_status || "");
    setFtype(user.family_type || "");
    setFvalues(user.family_values || "");
    setMsisters(user.married_sisters || "");
    setMbrothers(user.married_brothers || "");
    setParentsno(user.parents_number || "");
    setReligion(user.religion || "");
    setCaste(user.caste || "");
    setKulam(user.kulam || "");
    setStar(user.star || "");
    setRaasi(user.raasi || "");
    setFood(user.food_habits || "");
    setSmoking(user.smoking_habits || "");
    setDrinking(user.drinking_habits || "");

    if (user.education) {
      const selectedOptions = user.education.split(",").map((value) => {
        return { value: value.trim(), label: value.trim() };
      });
      setEducation(selectedOptions);
    }

    if (user.country) {
      fetch(`${API_BASE_URL}/api/states/${user.country}`)
        .then((response) => response.json())
        .then((data) => {
          setStates(data);
          setCities([]);
        })
        .catch((error) => {});
    }

    if (user.state) {
      fetch(`${API_BASE_URL}/api/cities/${user.state}`)
        .then((response) => response.json())
        .then((data) => setCities(data))
        .catch((error) => {});
    }
  }, [user]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/countries`)
      .then((response) => response.json())
      .then((data) => setCountries(data))
      .catch((error) => {});

    fetchReligiousOptions();
  }, []);

  const fetchReligiousOptions = async () => {
    const eduData = await fetchEducationData();
    const occData = await fetchOccupations();
    const raasiData = await fetchRaasiOptions();
    const starData = await fetchStarOptions();
    const casteData = await fetchCasteOptions();
    const kulamData = await fetchKulamOptions();

    setEducationOptions(eduData);
    setOccupationOptions(occData);
    setRaasiOptions(raasiData);
    setStarOptions(starData);
    setCasteOptions(casteData);
    setKulamOptions(kulamData);
  };

  const calculateAge = (dob) => {
    const dobDate = new Date(dob);
    const currentDate = new Date();
    const userAge = currentDate.getFullYear() - dobDate.getFullYear();
    setAge(userAge);
  };

  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value;
    setSelectedCountry(selectedCountryId);

    fetch(`${API_BASE_URL}/api/states/${selectedCountryId}`)
      .then((response) => response.json())
      .then((data) => setStates(data))
      .catch((error) => {});
  };

  const handleStateChange = (event) => {
    const selectedStateId = event.target.value;
    setSelectedState(selectedStateId);

    fetch(`${API_BASE_URL}/api/cities/${selectedStateId}`)
      .then((response) => response.json())
      .then((data) => setCities(data))
      .catch((error) => {});
  };

  function formatDOB(dob) {
    const dobDate = new Date(dob);
    const year = dobDate.getFullYear();
    const month = String(dobDate.getMonth() + 1).padStart(2, "0");
    const day = String(dobDate.getDate()).padStart(2, "0");
    const formattedDOB = `${year}-${month}-${day}`;
    return formattedDOB;
  }

  const onEditBasic = async (e) => {
    e.preventDefault();
    const selectedEducations = education.map((edu) => edu.value);
    try {
      await axios
        .put(
          `${API_BASE_URL}/api/update-basics`,
          {
            profile,
            marital,
            first,
            last,
            dob,
            age,
            gender,
            tongue,
            mobile,
            email,
            alt,
            education: selectedEducations,
            companyName,
            income,
            emptype,
            occupation,
            home,
            land,
            plat,
            address,
            address1,
            city,
            state,
            country,
            father,
            fcaste,
            fkulam,
            mother,
            mcaste,
            mkulam,
            fstatus,
            ftype,
            fvalues,
            msisters,
            mbrothers,
            parentsno,
            religion,
            caste,
            kulam,
            star,
            raasi,
            food,
            smoking,
            drinking,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          fetchUserData();
          navigate("/");
        });
    } catch (error) {
      alert("Failed to update");
    }
  };

  return (
    <div className="container co1">
      <header>Edit Profile</header>
      <form onSubmit={onEditBasic}>
        <div className="form first">
          <div className="details personal">
            <span className="title">Personal Details</span>
            <div className="fields">
              <div className="input-field">
                <label>First Name</label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={first}
                  onChange={(e) => setFirst(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={last}
                  onChange={(e) => setLast(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Date of Birth</label>
                <input
                  type="date"
                  placeholder="Enter birth date"
                  value={dob}
                  onChange={(e) => {
                    setDob(e.target.value);
                    calculateAge(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="input-field">
                <label>Age</label>
                <input type="tel" value={age} placeholder="age" readOnly />
              </div>
              <div className="input-field">
                <label>Gender</label>
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  required
                >
                  <option value="">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
              <div className="input-field">
                <label> Profile for</label>
                <select
                  value={profile}
                  onChange={(e) => setProfile(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="Myself">Myself</option>
                  <option value="Daughter">Daughter</option>
                  <option value="son">Son</option>
                  <option value="Brother">Brother</option>
                  <option value="sister">Sister</option>
                  <option value="Relative">Relative</option>
                  <option value="Friend">Friend</option>
                </select>
              </div>
              <div className="input-field">
                <label>Marital Status</label>
                <select
                  value={marital}
                  onChange={(e) => setMarital(e.target.value)}
                  required
                >
                  <option readOnly>Marital Status</option>
                  <option value="Married">Married</option>
                  <option value="UnMarried">UnMarried</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widower">Widower</option>
                  <option value="Awaiting divorce">Awaiting divorce</option>
                </select>
              </div>
              <div className="input-field">
                <label>Mother Tongue</label>
                <select
                  id="mother tongue"
                  value={tongue}
                  onChange={(e) => setTongue(e.target.value)}
                >
                  <option readOnly>Mother Tongue</option>
                  <option value="Tamil">Tamil</option>
                  <option value="Telugu">Telugu</option>
                  <option value="Malayalam">Malayalam</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Marathi">Marathi</option>
                  <option value="Gujarati">Gujarati</option>
                  <option value="Bengali">Bengali</option>
                  <option value="Punjabi">Punjabi</option>
                  <option value="Urdu">Urdu</option>
                  <option value="Sanskrit">Sanskrit</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="input-field">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Mobile Number</label>
                <input
                  type="tel"
                  placeholder="Enter mobile number"
                  value={mobile}
                  onChange={(e) => {
                    const mobileNum = e.target.value.replace(/\D/g, "");
                    setMobile(mobileNum);
                  }}
                  required
                />
              </div>
              <div className="input-field">
                <label>Alternative Number</label>
                <input
                  type="tel"
                  placeholder="Enter mobile number"
                  value={alt}
                  onChange={(e) => {
                    const altNum = e.target.value.replace(/\D/g, "");
                    setAlt(altNum);
                  }}
                  required
                />
              </div>
              <div className="input-field">
                <label>Education</label>
                <Select
                  options={educationOptions}
                  value={education}
                  className="input--style-2"
                  onChange={(selectedOptions) => setEducation(selectedOptions)}
                  isMulti
                  placeholder="Select Education"
                  styles={customStyles}
                />
              </div>
              <div className="input-field">
                <label>Company name & location</label>
                <input
                  type="text"
                  placeholder="Company name & loc"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="input-field">
                <label>Annual Income</label>
                <input
                  value={income}
                  type="text"
                  placeholder="Annual Income"
                  name="name"
                  onChange={(e) => setIncome(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Employment Type</label>
                <select
                  value={emptype}
                  onChange={(e) => setEmptype(e.target.value)}
                >
                  <option readOnly>Employment Type</option>
                  <option value="Employed">Employed</option>
                  <option value="Self-employed">Self-employed</option>
                  <option value="Out of work">Out of work</option>
                  <option value="Homemaker">Homemaker</option>
                  <option value="Student">Student</option>
                  <option value="Retired">Retired</option>
                  <option value="Unable to work">Unable to work</option>
                </select>
              </div>
              <div className="input-field">
                <label>Occupation</label>
                <select
                  value={occupation}
                  onChange={(e) => setOccupation(e.target.value)}
                >
                  <option readOnly>Occupation</option>
                  {occupationOptions.map((occ) => (
                    <option key={occ.id} value={occ.occupation}>
                      {occ.occupation}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Home Details</label>
                <select
                  value={home}
                  id="home-details"
                  onChange={(e) => setHome(e.target.value)}
                >
                  <option readOnly>Home Details</option>
                  <option value="Own House">Own House</option>
                  <option value="Rented House">Rented House</option>
                </select>
              </div>
              <div className="input-field">
                <label>Agriculture Land</label>
                <select
                  value={land}
                  id="land"
                  onChange={(e) => setLand(e.target.value)}
                >
                  <option readOnly>Agriculture Land</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
              <div className="input-field">
                <label>Flat Details</label>
                <select
                  value={plat}
                  id="plat"
                  onChange={(e) => setPlat(e.target.value)}
                >
                  <option readOnly>Flat Details</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>
          </div>
          <div className="details ID">
            <span className="title">Contact Details</span>
            <div className="fields">
              <div className="input-field">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="Enter Your Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Address 1</label>
                <input
                  type="text"
                  placeholder="Enter Your Address 1"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  required
                />
              </div>

              <div className="input-field">
                <label>Country</label>
                <select
                  value={country}
                  id="country"
                  onChange={(e) => {
                    setCountry(e.target.value);
                    handleCountryChange(e);
                  }}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-field">
                <label>State</label>
                <select
                  value={state}
                  id="state"
                  onChange={(e) => {
                    setState(e.target.value);
                    handleStateChange(e);
                  }}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-field">
                <label>City</label>
                <select
                  value={city}
                  id="city"
                  onChange={(e) => setCity(e.target.value)}
                >
                  <option>Select City</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="details ID">
            <span className="title">Family Details</span>
            <div className="fields">
              <div className="input-field">
                <label>Father Name</label>
                <input
                  type="text"
                  placeholder="Father Name"
                  value={father}
                  onChange={(e) => setFather(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Father Caste</label>
                <input
                  type="text"
                  placeholder="Father Caste"
                  value={fcaste}
                  onChange={(e) => setFcaste(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Father Kulam</label>
                <input
                  type="text"
                  placeholder="Father Kulam"
                  value={fkulam}
                  onChange={(e) => setFkulam(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Mother Name</label>
                <input
                  type="text"
                  placeholder="Mother Name"
                  value={mother}
                  onChange={(e) => setMother(e.target.value)}
                  required
                />
              </div>

              <div className="input-field">
                <label>Mother Caste</label>
                <input
                  type="text"
                  placeholder="Mother Caste"
                  value={mcaste}
                  onChange={(e) => setMcaste(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Mother Kulam</label>
                <input
                  type="text"
                  placeholder="Mother Kulam"
                  value={mkulam}
                  onChange={(e) => setMkulam(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Family Status</label>
                <select
                  value={fstatus}
                  id="family"
                  onChange={(e) => setFstatus(e.target.value)}
                  required
                >
                  <option readOnly>Family Status</option>
                  <option value="Middle className">Middle className</option>
                  <option value="Upper Middle className">
                    Upper Middle className
                  </option>
                  <option value="Rich">Rich</option>
                </select>
              </div>
              <div className="input-field">
                <label>Family Type</label>
                <select
                  value={ftype}
                  id="ftype"
                  onChange={(e) => setFtype(e.target.value)}
                  required
                >
                  <option readOnly>Family Type</option>
                  <option value="Joint Family">Joint Family</option>
                  <option value="Nuclear Family">Nuclear Family</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="input-field">
                <label>Family Values</label>
                <select
                  value={fvalues}
                  id="fvalues"
                  onChange={(e) => setFvalues(e.target.value)}
                  required
                >
                  <option readOnly>Family Values</option>
                  <option value="Orthodox">Orthodox</option>
                  <option value="Traditional">Traditional</option>
                  <option value="Moderate">Moderate</option>
                  <option value="Liberal">Liberal</option>
                </select>
              </div>
              <div className="input-field">
                <label>Married Sisters</label>
                <select
                  value={msisters}
                  id="msister"
                  onChange={(e) => setMsisters(e.target.value)}
                  required
                >
                  <option readOnly>Married Sisters</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className="input-field">
                <label>Married Brothers</label>
                <select
                  value={mbrothers}
                  id="mbrothers"
                  onChange={(e) => setMbrothers(e.target.value)}
                  required
                >
                  <option readOnly>Married Brothers</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className="input-field">
                <label>Parents Mobile Number</label>
                <input
                  type="tel"
                  value={parentsno}
                  placeholder="Parents mobile number"
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    setParentsno(numericValue);
                  }}
                  required
                />
              </div>
            </div>
          </div>

          <div className="details ID">
            <span className="title">Religious Information</span>
            <div className="fields">
              <div className="input-field">
                <label>Religious</label>
                <select
                  value={religion}
                  id="religion"
                  onChange={(e) => setReligion(e.target.value)}
                >
                  <option value="">Religion</option>
                  <option value="African Traditional &amp; Diasporic">
                    African Traditional &amp; Diasporic
                  </option>
                  <option value="Agnostic">Agnostic</option>
                  <option value="Atheist">Atheist</option>
                  <option value="Baha'i">Baha'i</option>
                  <option value="Buddhism">Buddhism</option>
                  <option value="Cao Dai">Cao Dai</option>
                  <option value="Chinese traditional religion">
                    Chinese traditional religion
                  </option>
                  <option value="Christianity">Christianity</option>
                  <option value="Hinduism">Hinduism</option>
                  <option value="Islam">Islam</option>
                  <option value="Jainism">Jainism</option>
                  <option value="Juche">Juche</option>
                  <option value="Judaism">Judaism</option>
                  <option value="Neo-Paganism">Neo-Paganism</option>
                  <option value="Nonreligious">Nonreligious</option>
                  <option value="Rastafarianism">Rastafarianism</option>
                  <option value="Secular">Secular</option>
                  <option value="Shinto">Shinto</option>
                  <option value="Sikhism">Sikhism</option>
                  <option value="Spiritism">Spiritism</option>
                  <option value="Tenrikyo">Tenrikyo</option>
                  <option value="Unitarian-Universalism">
                    Unitarian-Universalism
                  </option>
                  <option value="Zoroastrianism">Zoroastrianism</option>
                  <option value="primal-indigenous">primal-indigenous</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="input-field">
                <label>Caste</label>
                <select
                  value={caste}
                  id="caste"
                  onChange={(e) => setCaste(e.target.value)}
                >
                  <option readOnly>Caste</option>
                  {casteOptions.map((caste) => (
                    <option key={caste.id} value={caste.caste}>
                      {caste.caste}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Kulam</label>
                <select
                  value={kulam}
                  id="kulam"
                  onChange={(e) => setKulam(e.target.value)}
                >
                  <option readOnly>Kulam</option>
                  {kulamOptions.map((kulam) => (
                    <option key={kulam.id} value={kulam.kulam}>
                      {kulam.kulam}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Raasi</label>
                <select
                  value={raasi}
                  id="raai"
                  onChange={(e) => setRaasi(e.target.value)}
                >
                  <option readOnly>Raasi</option>
                  {raasiOptions.map((raasi) => (
                    <option key={raasi.id} value={raasi.rasiname}>
                      {raasi.rasiname}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field">
                <label>Star</label>
                <select
                  value={star}
                  id="star"
                  onChange={(e) => setStar(e.target.value)}
                >
                  <option readOnly>Star</option>
                  {starOptions.map((star) => (
                    <option key={star.id} value={star.natchaname}>
                      {star.natchaname}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="input-field">
                <label>Dosham</label>
                <input
                  type="text"
                  placeholder="Dosham"
                  value={user.dosham}
                  required
                />
              </div> */}
            </div>
          </div>

          <div className="details ID">
            <span className="title">Habit Details</span>
            <div className="fields">
              <div className="input-field">
                <label>Food Habit</label>
                <select
                  value={food}
                  id="food"
                  onChange={(e) => setFood(e.target.value)}
                >
                  <option readOnly>Food Habits</option>
                  <option value="Vegetarian">Vegetarian</option>
                  <option value="non-Vegetarian">non-Vegetarian</option>
                  <option value="Eggetarian">Eggetarian</option>
                  <option value="All">All</option>
                </select>
              </div>
              <div className="input-field">
                <label>Smoking Habit</label>
                <select
                  value={smoking}
                  id="smoking"
                  onChange={(e) => setSmoking(e.target.value)}
                >
                  <option readOnly>smoking Habits</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                  <option value="Occasionally">Occasionally</option>
                </select>
              </div>
              <div className="input-field">
                <label>Drinking Habit</label>
                <select
                  value={drinking}
                  id="drinking"
                  onChange={(e) => setDrinking(e.target.value)}
                >
                  <option readOnly>Drinking Habits</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                  <option value="Occasionally">Occasionally</option>
                </select>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button type="submit" className="backBtn">
                Save Profile
                <i className="uil uil-navigator"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
