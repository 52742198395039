import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import HomeDailyRecom from "./HomeDailyRecom";
import HomeProf from "./HomeProf";
import HomeTotalMatch from "./HomeTotalMatch";
import HomePartPref from "./HomePartPref";
import UserContext from "../Authcontext";

import Header from "../Header/Header";
import Footer from "../Foote/Footer";

import "./home.css";

const Home = () => {
  const { user, fetchUserData } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (user.verify === 1) {
      navigate("/", { replace: true });
    } else {
      navigate("/user-under-verification", { replace: true });
    }
  }, [user.verify, navigate]);

  return (
    <>
      <Header file={user.filename} name={user.first_name} />
      <div className="home-cont">
        <HomeProf />
        <div className="home-body-container">
          <HomeDailyRecom />
          <HomePartPref />
          <HomeTotalMatch />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
