import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../api_config";
import logo from "../Assets/logo.png";
import AuthContext from "../AdminAuthContext";

import "./login.css";

export default function AdminLogin() {
  const { login, isAdminAuthenticated } = useContext(AuthContext);
  const [adUser, setAdUser] = useState("");
  const [adPass, setAdPass] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (isAdminAuthenticated) {
      navigateToHome();
    }
  }, [isAdminAuthenticated]);

  const navigateToHome = () => {
    return <Navigate to="/admin" replace />;
  };

  const handleAdminLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/admin-login`,
        {
          username: adUser,
          password: adPass,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        login();
        navigate("/admin");
      } else if (response.data.error) {
        setError(response.data.error);
      }
    } catch (error) {
      alert("Something error occured");
    }
  };

  return (
    <div className="admin-login-cont">
      <img
        src={logo}
        alt="Vokkaliga matrimony"
        style={{ paddingTop: "20px", paddingLeft: "20px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          marginTop: "-80px",
        }}
      >
        <Form className="ad-log-container" onSubmit={handleAdminLogin}>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              value={adUser}
              placeholder="Username"
              onChange={(e) => setAdUser(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={adPass}
              placeholder="Password"
              onChange={(e) => setAdPass(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Sign In
          </Button>
          {error && <p style={{color: "red"}}>{error}</p>}
        </Form>
      </div>
    </div>
  );
}
