import axios from "axios";
import { API_BASE_URL } from "./api_config";

export const fetchEducationData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/education`);
    const transformedEducationData = response.data.map((edu) => ({
      value: edu.Education,
      label: edu.Education,
    }));
    return transformedEducationData;
  } catch (error) {
    return [];
  }
};

export const fetchEduData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/education`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchOccupations = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/occupation`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchMultiOccuData = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/occupation`);
    const transformedOccuData = response.data.map((edu) => ({
      value: edu.occupation,
      label: edu.occupation,
    }));
    return transformedOccuData;
  } catch (error) {
    return [];
  }
};

export const fetchRaasiOptions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/raasi`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchStarOptions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/star`);
    return response.data;
  } catch (error) {
    return []
  }
};

export const fetchMultiStar = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/star`);
    const multiStarSelect = response.data.map((star) => ({
      value: star.natchaname,
      label: star.natchaname,
    }))
    return multiStarSelect
  } catch (err) {
    console.log("Err fetching multi star data:", err);
    return []
  }
}

export const fetchMultiRaasi = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/raasi`);
    const multiRaasiSelect = response.data.map((raasi) => ({
      value: raasi.rasiname,
      label: raasi.rasiname,
    }))
    return multiRaasiSelect
  } catch (error) {
    return [];
  }
};

export const fetchCasteOptions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/caste`);
    return response.data
  } catch (error) {
    return [];
  }
};

export const fetchKulamOptions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/kulam`);
    return response.data
  } catch (error) {
    return [];
  }
};

export const fetchMultiKulam = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/kulam`);
    const multiKulamSelect = response.data.map((kulam) => ({
      value: kulam.kulam,
      label: kulam.kulam,
    }))
    return multiKulamSelect
  } catch (error) {
    return [];
  }
};

export const fetchCountries = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/countries`)
    return response.data
  } catch (error) {
    return [];
  }
}
export const fetchCities = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/cities`)
    return response.data
  } catch (error) {
    return [];
  }
}