import React, { useContext, useState } from "react";
import axios from "axios";
import "./forgotpass.css";
import ForgotPassContext from "./ForgotPassContext";
import { API_BASE_URL } from "../api_config";
import { useNavigate } from "react-router-dom";

function FPass() {
  const { userData } = useContext(ForgotPassContext);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false); 

    const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match and perform update logic
    if (newPassword === confirmPassword) {
      setShowAlert(false);

      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/update-password`,
          {
            userId: userData.userId,
            newPassword: confirmPassword,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if(response.status === 200){
            navigate("/signup", {replace: true})
        }
      } catch (error) {
        alert("Something error occured");
      }
    } else {
      setShowAlert(true); 
    }
  };

  return (
    <div className="up">
      <div className="up1">
        <h2>Update Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label htmlFor="updatePassword">New Password</label>
            <input
              type="tel"
              id="updatePassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
            />
          </div>
          <div className="input-container">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
            />
          </div>
          {showAlert && <p className="alert">Passwords do not match.</p>}
          <button type="submit">Update Password</button>
        </form>
      </div>
    </div>
  );
}

export default FPass;
