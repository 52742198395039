import React, { useState, useContext } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import logo from "../../assets/logo.png";
import { API_BASE_URL } from "../api_config";

import "./login.css";
import UserContext from "../Authcontext";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const { setUser, setToken } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false); // Step 1: Initialize loading state

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  let navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogin = async (e) => {
    e.preventDefault();

    // Step 3: Set loading to true when the login button is clicked
    setLoading(true);

    const response = await axios.post(
      `${API_BASE_URL}/api/user-login`,
      {
        email,
        password,
      },
      { withCredentials: true }
    );

    // Step 4: Set loading back to false after receiving a response

    if (response.data.auth && email !== "" && password !== "") {
      setUser({ userId: response.data.userId });
      setToken(response.data.token);
      Cookies.set("m_host-token", response.data.token, { expires: 1 / 24 });
      setLoading(false);
      if (response.data.verify === 1) {
        navigate("/", { replace: true }); // Navigate to "/" if user.verify === 1
      } else {
        navigate("/user-under-verification", { replace: true }); // Navigate to "/user-under-verification" otherwise
      }
      setEmail("");
      setPassword("");
    } else {
      setEmail("");
      setPassword("");
      setLoading(false);
      setError(response.data.error);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="log-btn">
        Login
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <div className="reg-login-logo-cont">
            <img src={logo} alt="Tamil Matrimony" className="logo-log-img" />
            <p className="reg-login-desc">Welcome back! Please Login</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="reg-form-body-details">
            <form className="reg-log-from-details">
              <input
                type="text"
                value={email}
                placeholder="Enter Email or Mobile"
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                value={password}
                placeholder="Enter password"
                className="form-control"
                onChange={(e) => setPassword(e.target.value)}
              />

              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleLogin}
                disabled={loading} // Disable the button when loading is true
              >
                {loading ? "Logging in..." : "Login"}
              </button>
              {error && <div className="error-login">{error}</div>}
            </form>
            <Link to="/login/identify">Forgot password?</Link>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
