import { useState, useEffect, useContext } from "react";
import UserContext from "../Authcontext";
import axios from "axios";
import { API_BASE_URL } from "../api_config";
import { Link, useLocation } from "react-router-dom";
import { CgProfile } from "react-icons/cg";

const HomeDailyRecom = () => {
  const { user } = useContext(UserContext);
  const [dailyRecProf, setDailyRecProf] = useState([]);

  const dailyRecomPath = useLocation();

  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/api/daily-rec`,
        {
          params: {
            id: user.user_id,
            gender: user.gender,
            membership: user.pay_membership,
            min_age: user.pmin_age,
            max_age: user.pmax_age,
            maritalStatus: user.pmarital_status,
            language: user.pmother_tongue,
            edu: user.phighest_education,
            empType: user.pemployment_type,
            occu: user.poccupation,
            income: user.pannual_income,
            religion: user.preligion,
            caste: user.pcaste,
            kulam: user.pkulam,
            star: user.pstar,
            raasi: user.praasi,
            height: user.pheight,
            weight: user.pweight,
            bodyType: user.pbody_type,
            complexion: user.pcomplexion,
            physicalStatus: user.pphysical_status,
          },
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (Array.isArray(res.data)) {
          // console.log("count of daily recom: " + res.data.length);
          // console.log("daily recom data: " + JSON.stringify(res.data));
          setDailyRecProf(res.data);
        }
      })
      .catch((err) => {});
  }, [user]);

  const renderDailyRecom = () => {
    return dailyRecProf.slice(0, 5).map((prof, i) => (
      <li className="prof-daily-recom-item" key={i}>
        <Link
          to={"/view-profile"}
          state={{ userId: prof.user_id }}
          className="prof-daily-recom-item"
        >
          {prof.filename === null ? (
            <CgProfile className="prof-daily-recom-img" />
          ) : (
            <img
              src={`${API_BASE_URL}/uploads/` + prof.filename}
              alt={`${prof.first_name}`}
              className="prof-daily-recom-img"
            />
          )}
          <p className="prof-daily-recom-name">{prof.first_name}</p>
        </Link>
      </li>
    ));
  };

  return (
    <div className="prof-daily-recom-container">
      <div className="prof-total-matches-heading">
        <h3 className="prof-daily-recom-heading">Your Daily Recommendations</h3>
        {dailyRecomPath.pathname === "/" && (
          <Link to="/matches/daily-recommendations">See All</Link>
        )}
      </div>
      <ul className="prof-daily-recom-lists">{renderDailyRecom()}</ul>
    </div>
  );
};

export default HomeDailyRecom;
